import { CoverImage } from "./helper/CoverImage";
import clientCover from "../Assets/coverImage/ourClient.png";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import { SubTitle, Title } from "../Styles/StyleComponent";
import BackgroundContainer from "../utils/BackgroundContainer";
import { GlobalData } from "../contextapi/ContextProvider";
import { useContext } from "react";

export const TermsAndCondition = () => {

  let {
    settingsCollection
    } = useContext(GlobalData);


  return (
    <>
 <BackgroundContainer title={'Terms & Condition'} description={'Website Terms and Conditions of Use'} > 

 <Container className="py-5">

 <Col xs={12}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Terms & Condition</span></Title>
      </Fade>
      </Col>
      </Row>
    </Col>


    <div>
      <p dangerouslySetInnerHTML={{ __html: settingsCollection?.terms_condition || '' }}></p>
    </div>
  </Container>

      </BackgroundContainer>
    </>
  );
};
