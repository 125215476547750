import { strings } from "../../Api/Localization";
import { Button, EllipsisText } from "../../Styles/StyleComponent";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";
import {
  HostingWithDollarVector,
  ManAppDevVector,
  ManCodingVector,
} from "../../Assets/ServicesIcon";
import { NavLink, useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import sms from "../../Assets/products/sms.png";
import webdevelopment from "../../Assets/products/webdevelopment.png";

import blog1 from "../../Assets/blogs/blog1detail.jpg";
import blog2 from "../../Assets/blogs/blog4.jpg";
import blog3 from "../../Assets/blogs/blog2.jpeg";
import blog4 from "../../Assets/blogs/blog3.jpg";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";
import { dateConvert } from "../../utils/dateConvert";




export const ServiceSliders = () => {
  const navigateTo = useNavigate();

  let {
    allpagesCollection
    } = useContext(GlobalData);


  const services = [
    {
      id: 1,
      svg: (
        <img
        src={allpagesCollection.service_1_image}
        alt=""
        width="100%"
        height="100%"
        style={{ objectFit: "contain" }}
      />
      ),
      title: strings.getString("software_development"),
      description: allpagesCollection.service_1_description ? <p dangerouslySetInnerHTML={{ __html: allpagesCollection.service_1_description }} ></p> : strings.getString("software_development_description"),
      path: "services/software-development",
    },
    {
      id: 2,
      svg: (
        <img
        src={allpagesCollection.service_2_image}
        alt=""
        width="100%"
        height="100%"
        style={{ objectFit: "contain" }}
      />
      ),
      title: strings.getString("mobile_app_development"),
      description: allpagesCollection.service_2_description ? <p dangerouslySetInnerHTML={{ __html: allpagesCollection.service_2_description }} ></p> : strings.getString("mobile_app_development_description"),
      path: "services/mobile-app-development",
    },
    {
      id: 3,
      svg: (
        <img
        src={allpagesCollection.service_3_image}
        alt=""
        width="100%"
        height="100%"
        style={{ objectFit: "contain" }}
      />
      ),
      title: strings.getString("web_application_development"),
      description: allpagesCollection.service_3_description ? <p dangerouslySetInnerHTML={{ __html: allpagesCollection.service_3_description }} ></p> : strings.getString("web_application_development_description"),
      path: "services/web-application-development",
    },
    {
      id: 4,
      svg: (
        <img
        src={allpagesCollection.service_4_image}
        alt=""
        width="100%"
        height="100%"
        style={{ objectFit: "contain" }}
      />
      ),
      title: strings.getString("domain_registration_web_hosting"),
      description: allpagesCollection.service_4_description ? <p dangerouslySetInnerHTML={{ __html: allpagesCollection.service_4_description }} ></p> : strings.getString(
        "domain_registration_web_hosting_description"
      ),
      path: "services/domain-registration-web-hosting",
    },
    {
      id: 5,
      svg: (
        <img
        src={allpagesCollection.service_5_image}
        alt=""
        width="100%"
        height="100%"
        style={{ objectFit: "contain" }}
      />
      ),
      title: strings.getString("bulk_sms_service"),
      description:allpagesCollection.service_5_description ? <p dangerouslySetInnerHTML={{ __html: allpagesCollection.service_5_description }} ></p> :  strings.getString("bulk_sms_service_description"),
      path: "services/bulk-sms-service",
    },
  ];

  return (
    <>
      <Swiper
        className="py-5  px-lg-5 px-md-4 px-sm-3 px-2 "
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
      >
        {services.map((service: any, i: number) => {
          return (
            <SwiperSlide key={service.id}>
              <Fade top delay={i * 50}>
                <div>
                  <div className="cardsServices">
                    <div style={{ height: "200px", width: "100%" }}>
                      {service.svg}
                    </div>

                    <div className="p-2">
                      <h4 className="mt-2 text-center" style={{color : '#0082C6'}}>{service.title}</h4>
                      <EllipsisText>{service.description}</EllipsisText>

                      <div className="btn-box d-flex justify-content-center">
                      <Button
                        className="mt-4 mx-auto text-center"
                        background="#0082C6"
                        padding="10px 15px"
                        style={{
                          width: "140px",
                          boxShadow: "0px 27px 40px 12px rgb(34 39 49 / 15%)",
                          borderRadius: "7px",
                        }}
                        onClick={() => {
                          navigateTo(`/${service.path}`);
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        {strings.getString("read_more")}
                      </Button>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </Fade>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};



export const HomeBlogsSliders = () => {
  
  let { blogsCollection,} = useContext(GlobalData);
  return (
    <>
      <Swiper
        className="py-5  px-lg-5 px-md-4 px-sm-3 px-2 "
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
      >
        {blogsCollection?.sort((a:any, b:any) => a.orderAt - b.orderAt)?.map((service: any, i: number) => {
          return (
            <SwiperSlide key={service.id}>
              <Fade top delay={i * 50}>
                <div className="w-100">
                  <div className="cardsServices" style={{borderRadius : '16px'}}>
                    <div className="mb-3 img-box" style={{ height: "260px", width: "100%"}}>
                      <img src={service.image} alt="" style={{height: '100%',width : '100%' ,borderRadius : '16px' }} />
                    </div>

                    <div className="p-2 text-start w-100">
                      <EllipsisText lineClamp="2">{dateConvert(service.createdAt)}</EllipsisText>
                      <NavLink to={`/resources/blogs/${service.id}/${service.title}`} onClick={()=>{window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });}} className='text-black' ><EllipsisText lineClamp="2" fontSize="24px" style={{fontWeight : '800'}}>{service.title}</EllipsisText>    </NavLink>          
                      <NavLink to={`/resources/blogs/${service.id}/${service.title}`} onClick={()=>{window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });}}  style={{color : '#0082C6'}}>Learn More <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
<path d="M20.7796 6.53033C21.0725 6.23744 21.0725 5.76256 20.7796 5.46967L16.0066 0.696699C15.7137 0.403806 15.2388 0.403806 14.9459 0.696699C14.653 0.989592 14.653 1.46447 14.9459 1.75736L19.1886 6L14.9459 10.2426C14.653 10.5355 14.653 11.0104 14.9459 11.3033C15.2388 11.5962 15.7137 11.5962 16.0066 11.3033L20.7796 6.53033ZM0.00354004 6.75H20.2492V5.25H0.00354004V6.75Z" fill="#0082C6"/>
</svg> </NavLink>
               
                     
                    </div>
                  </div>
                </div>
              </Fade>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};



