import { Col, Container, Row } from "react-bootstrap";
import { strings } from "../../Api/Localization";
import academicErp from "../../Assets/coverImage/academicErp.png";
import { CoverImage } from "../helper/CoverImage";
import { Fade } from "react-reveal";
import staff from "../../Assets/products/staff.png";
import hr from "../../Assets/products/hr.png";
import time from "../../Assets/products/time.png";
import systemhr from "../../Assets/products/systemhr.png";
import {
  ContainerTitle,
  ParagraphText,
  SubTitle,
  Title,
  Title40,
} from "../../Styles/StyleComponent";
import {
  ArrowBoxFirst,
  ArrowBoxLeft,
  ArrowTipBox,
  BlueWave,
  YellowWave,
} from "./AcademicErp";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { GlobalData } from "../../contextapi/ContextProvider";
import { useContext } from "react";
import { ProductOddItems } from "./ProductHelper";

export const HrAndPayRoll = () => {

  let {
    allpagesCollection,mobile
    } = useContext(GlobalData);


    const academicProducts = {
      time: {
        features: [
          "electronic_attendance_processing",
          "over_time_or_under",
          "over_time_or_under",
          "dynamic_shift_management",
          "online_request_or_approval"
        ],
      },
      payrollManagement: {
        features: [
          "timely_and_accurate_salary_calculation",
          "wage_calculation",
          "late_or_early_deduction_calculation",
          "salary_sheet",
          
        ],
      },
      systemhr: {
        features: [
          
    "Streamlining_HR_Operations",
    "Enhancing_Data_Accuracy",
    "Employee_Empowerment"
          
        ],
      },
      staffManagment: {
        features: [
          "user_definable_performance_standard",
          "user_definable_performance_rating",
          "automatic_job_post",
          "view_real_time_application_data",
          "automatic_job_post"

        ],
      }
    }
    const newModules = [
      {
        id: 0,
        icon : time,
        title : strings.getString("time_attendance_system"),
        para : (<p>Accurate time tracking is the key to preventing payroll errors and ensuring a seamless payroll process. It's not just about efficiency; it's also about compliance, especially when it comes to managing overtime.

        <br/>Our Time Attendance System empowers your organization to maintain precise records of employee attendance. This not only minimizes payroll discrepancies but also ensures that you stay compliant with regulations governing overtime and working hours.
        
        <br/>Experience the benefits of error-free payroll processing and peace of mind knowing you're meeting regulatory requirements with our Time Attendance System.
        </p>),
        products: academicProducts.time
      },
      {
        id: 1,
        icon : hr,
        title : strings.getString("payroll_management_system"),
        para : (<p>Our Payroll Management System is designed to reduce potential errors in payroll processing while optimizing system performance. It simplifies the intricate task of wage calculations, tax determination, tax payments to government authorities, and meticulous financial record-keeping, including bonuses, deductions, and net pay.

<br/>Experience the benefits of error reduction and streamlined payroll operations with our Payroll Management System, ensuring both accuracy and efficiency in your financial processes.
          </p>),
        products: academicProducts.payrollManagement
      },
      {
        id: 2,
        icon : systemhr,
        title : strings.getString("pis_hr"),
        para : strings.getString("pis_hr_description"),
        products: academicProducts.systemhr
      },
      {
        id: 3,
        icon : staff,
        title : strings.getString("staff_appraisal_system"),
        para : strings.getString("staff_appraisal_system_description"),
        products: academicProducts.staffManagment
      },

    ]

  return (
    <>
      {/* <CoverImage image={academicErp} path="hr_and_payroll" type="products" /> */}


      <BackgroundContainer title={allpagesCollection.product_4_title} description={''} > 


      <Container className="my-5 ">
      <Row className="py-4">

      <Col xs={12} style={{marginBottom : mobile ? 20: 60}}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Effectively Nurturing Human Capital </span></Title>
           <p>Payroll processing is just one aspect of our HR and Payroll Management solution. It's a feature-rich application made to help you manage and grow your workforce, which is your most valuable asset.</p>
      </Fade>
      </Col>
      </Row>
    </Col>

          <Col lg={8} className="bg-grey order-2 order-sm-1">
          <Fade up delay={200}>
           <Title40 > <span style={{color:"#233d63"}}>{allpagesCollection.product_4_title}</span></Title40>
      </Fade>
          <Fade up delay={200}>   <ParagraphText color="#000">

          <div className="blogParagraph" dangerouslySetInnerHTML={{ __html: allpagesCollection.product_4_description || '' }} ></div>

          </ParagraphText>
            </Fade>
          </Col>
          <Col
            lg={4}
            className=" d-flex justify-content-lg-end justify-content-center  order-1 order-sm-2"
          >
            <img src={allpagesCollection.product_4_image} alt="" className="img-fluid animatedUp" style={{zIndex: '2' , objectFit : 'contain'}} />
          </Col>
        </Row>
      </Container>

      <Container  className="mb-5">
        <Row>
        {newModules?.map((val,index)=>{
            return(<>

<Col xl={6} className="my-lg-3 my-3" key={index}>
              <div className="cardsServices-new">
                <h4 className="mt-2 border-bottom w-100 p-3"> <img src={val.icon} style={{height : '30px' , width : '30px'}} alt="" className="me-2" /> {val.title}</h4>
                <p className="px-3">{val.para}</p>
               {val.products && <ProductOddItems products={val.products} />}
               {/* {index%2 === 0 ? <ProductOddItems products={val.products} /> : <ProductEvenItems products={val.products} />} */}
              </div>
            </Col>


            </>)
          }) }
        </Row>
      </Container>

      {/* <div className="position-relative">
        <YellowWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={4}>
              <div className="  d-flex " style={{ height: "300px" }}>
                <img
                  src={time}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
            <Col lg={8}>
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("time_attendance_system")}
                </ContainerTitle>
                <Fade up delay={0}>
                  <ParagraphText color="#000">
                    {strings.getString("time_attendance_system_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div
                className="d-flex flex-wrap justify-content-md-start justify-content-center "
                style={{ marginLeft: "25px" }}
              >
                <ArrowBoxLeft
                  firstText="electronic_attendance_processing"
                  secondText="over_time_or_under"
                />
                <ArrowBoxLeft
                  firstText="over_time_or_under"
                  secondText="dynamic_shift_management"
                />
                <ArrowBoxLeft firstText="online_request_or_approval" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="position-relative">
        <BlueWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={8} className="order-lg-1 order-2">
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("payroll_management_system")}
                </ContainerTitle>
                <Fade up delay={0}>
                  <ParagraphText color="#000">
                    {strings.getString("payroll_management_system_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap position-relative justify-content-md-start justify-content-center">
                <ArrowTipBox
                  color="#F9A51B"
                  text="timely_and_accurate_salary_calculation"
                />
                <ArrowTipBox color="#007EC5" text="bonus_nad_ot_calculation" />
                <ArrowTipBox color="#F9A51B" text="wage_calculation" />
                <ArrowTipBox
                  color="#007EC5"
                  text="late_or_early_deduction_calculation"
                />
                <ArrowTipBox color="#F9A51B" text="salary_sheet" />
              </div>
            </Col>
            <Col lg={4} className="order-lg-2 order-1">
              <div
                className=" d-flex  justify-content-end"
                style={{ height: "300px" }}
              >
                <img
                  src={hr}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="position-relative">
        <YellowWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={4}>
              <div className=" d-flex " style={{ height: "300px" }}>
                <img
                  src={systemhr}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
            <Col lg={8}>
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>{strings.getString("pis_hr")}</ContainerTitle>

                <Fade up delay={0}>
                  <ParagraphText color="#000">
                    {strings.getString("pis_hr_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap " style={{ marginLeft: "25px" }}>
                <ArrowBoxFirst
                  firstText="timely_and_accurate_salary_calculation"
                  secondText="bonus_nad_ot_calculation"
                />
                <ArrowBoxLeft
                  firstText="wage_calculation"
                  secondText="late_or_early_deduction_calculation"
                />
                <ArrowBoxLeft firstText="salary_sheet" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="position-relative">
        <BlueWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={8} className="order-lg-1 order-2">
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("staff_appraisal_system")}
                </ContainerTitle>
                <Fade up delay={0}>
                  <ParagraphText color="#000">
                    {strings.getString("staff_appraisal_system_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap position-relative justify-content-md-start justify-content-center">
                <ArrowTipBox
                  color="#F9A51B"
                  text="user_definable_performance_standard"
                />
                <ArrowTipBox
                  color="#007EC5"
                  text="user_definable_performance_rating"
                />
                <ArrowTipBox color="#F9A51B" text="automatic_job_post" />
                <ArrowTipBox
                  color="#007EC5"
                  text="view_real_time_application_data"
                />
              </div>
            </Col>
            <Col lg={4} className="order-lg-2 order-1">
              <div
                className=" d-flex  justify-content-end"
                style={{ height: "300px" }}
              >
                <img
                  src={staff}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      </BackgroundContainer>
    </>
  );
};
