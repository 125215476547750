import axios from "axios"


const apiUrl = process.env.REACT_APP_APIURL

export const statsGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/stats` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}


export const leadsPost = async (collectData) =>{
    try{
        
        const response = await axios.post(`${apiUrl}/api/v1/contact` , collectData );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}

export const dealershipPost = async (collectData) =>{
    try{
        
        const response = await axios.post(`${apiUrl}/api/v1/dealership` , collectData );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}


export const settingsGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/settings` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}


export const portfolioGet = async () =>{
    try{
        const response = await axios.get(`${apiUrl}/api/v1/portfolio` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}


export const careerlistGet = async () =>{
    try{
        const response = await axios.get(`${apiUrl}/api/v1/careerlist` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}

export const careerappliedApi = async (collectData) =>{
    console.log(collectData , 'collectDatacollectDatacollectData')
    try{
        let formData = new FormData();
        formData.append('full_name' , collectData.full_name);
        formData.append('email' , collectData.email);
        formData.append('cv' , collectData.cv);
        formData.append('photo' , collectData.photo);
        formData.append('contact_number' , collectData.contact_number);
        formData.append('applying_for' , collectData.applying_for);
        formData.append('cover_letter' , collectData.cover_letter);


        let response = await axios.post(`${apiUrl}/api/v1/careerapplied` , formData);
        return response.data
    }
    catch(err){
        return err.response.data
    }
}



export const clientGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/client` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}




export const blogsGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/blog` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}




export const mediaGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/media` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}





export const allpagesGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/allpages` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}




export const sliderGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/slider` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}



export const testmonialGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/testmonial` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}



export const achievementGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/achievement` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}


export const mediaCoverageGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/mediaCoverage` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}
