import { Col } from "react-bootstrap";
import styled from "styled-components";
import { CustomLink } from "../Components/Navbar/NavbarStyles";

interface CustomProps {
  readonly lineClamp?: string;
  readonly fontSize?: string;
  readonly fontWeight?: string;
  readonly color?: string;
  readonly textTransform?: string;
  readonly image?: any;
  readonly justifyContent?: string;
  readonly background?: string;
  readonly transform?: string;
  readonly margin?: string;
  readonly alignItems?: string;
  readonly padding?: string;
  readonly minWidth?: string;
  readonly url?: string;
  readonly bottom?: string;
  readonly top?: string;
  readonly left?: string;
  readonly border?: string;
  readonly lineHeight?: string;
  readonly boxShadow?: string;
  readonly backgroundImage?: string;
  readonly transformHeight?: string;
  readonly transformDistance?: string;
  readonly transformFactor?: string;
  readonly display?: string;
}

export const Button = styled.button<CustomProps>`
  background: ${(props: any) => props.background ?? "#1f5d9e"};
  border-radius: 8px;
  padding: ${(props: any) => props.padding ?? "4px 18px"};
  border: ${(props: any) => props.border ?? "none"};
  font-size: 18px;
  color: ${(props: any) => props.color ?? "white"};
  &:hover {
    background: ${(props: any) => props.background ?? "#1f5d9e"};
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  @media (max-width: 992px) {
    margin-bottom: 12px;
  }
`;

export const EllipsisText = styled.p<CustomProps>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${(props: CustomProps) => props.lineClamp ?? "4"};
  -webkit-box-orient: vertical;
  margin: ${(props: CustomProps) => props.margin ?? "0px"};
  font-size: ${(props: CustomProps) => props.fontSize ?? "14px"};
  color: ${(props: CustomProps) => props.color ?? "#000000"};
  font-weight: ${(props: CustomProps) => props.fontWeight ?? "normal"};
  text-transform: ${(props: CustomProps) => props.textTransform ?? "none"};
  line-height: ${(props) => props.lineHeight ?? "inherit"};
`;

export const EllipsisDiv = styled.div<CustomProps>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${(props: CustomProps) => props.lineClamp ?? "4"};
  -webkit-box-orient: vertical;
  font-size: ${(props: CustomProps) => props.fontSize ?? "14px"};
  color: ${(props: CustomProps) => props.color ?? "#000000"};
  font-weight: ${(props: CustomProps) => props.fontWeight ?? "normal"};
  text-transform: ${(props: CustomProps) => props.textTransform ?? "none"};
  line-height: ${(props) => props.lineHeight ?? "initial"};
  // for all browsers
  > p {
    margin-bottom: 0px;
  }
  > * {
    &:not(:first-child) {
      display: ${(props) => props.display ?? "-webkit-box"};
    }
  }
`;

export const SliderContainer = styled.div`
  height: 205px;
  margin: 8px;
  position: relative;
  display: flex;
  text-align: initial;
`;

export const TestimonialAvatar = styled.img`
  width: 100%;
  height: 200px;
  background: rgb(224, 238, 236);
  border-radius: 28px 0px;
  border: none;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
`;

export const TitleText = styled.h1<CustomProps>`
  font-weight: ${(props) => props.fontWeight ?? "600"};
  color: ${(props: any) => props.color ?? "#000"};
  font-size: 2.2rem;
`;

export const Title40 = styled.h1<CustomProps>`
  font-weight: ${(props) => props.fontWeight ?? "700"};
  color: ${(props: any) => props.color ?? "#233d63"};
  font-size: 40px;

  @media (max-width: 768px) {
    font-size: 30px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }


`;

export const SubTitleText = styled.h5<CustomProps>`
  color: ${(props: any) => props.color ?? "#F9A51B"};
  font-weight: ${(props) => props.fontWeight ?? "600"};
`;
export const ContainerTitle = styled.h5`
  padding: 8px 10px;
  text-align: center;
  background: rgb(2, 82, 136);
  border-radius: 60px 0px;
  color: ${(props: any) => props.color ?? "white"};
  font-weight: bold;
  max-width: 420px;
`;
export const FilterImage = styled.div.attrs(() => ({
  className: " d-flex justify-content-center align-items-center",
}))`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  background: rgb(65, 189, 255, 0.1);
  height: 140px;
  width: 140px;
  border-radius: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  &:hover {
    ${FilterImage} {
      filter: grayscale(0);
    }
    transform: scale(1.2);
  }
`;

export const SocialIcons = styled.div<CustomProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: ${(props) => props.background ?? "#f9a51b"};
  border-radius: 28px;
  margin-bottom: 4px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(to right, #f9a51b 0, #f9a51b 51%, #f9a51b 100%);
  }
`;

export const ParagraphText = styled.p`
  margin: 0px;
  color: ${(props: any) => props.color ?? "white"};
  display: inline-block;
`;

export const MemberImage = styled.div<CustomProps>`
  background-image: ${(props: any) =>
    props.image ??
    "linear-gradient( to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))"};
  height: 200px;
  min-width: 180px;
  border-radius: 12px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 220px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const ImageFooter = styled.div`
  background: rgb(0, 126, 197, 0.9);
  position: absolute;
  bottom: 0px;
  line-height: 18px;
  width: 100%;
  padding: 6px 8px;
`;

export const BR = styled.br`
  content: "";
  margin: 2em;
  display: block;
  font-size: 24%;
`;

export const Ellipse = styled.div`
  height: 160px;
  border-radius: 50%;
  background: rgb(241, 241, 241);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  max-width: 160px;
  transition: all 1s;
  &:hover {
    filter: grayscale(0);
    transform: scale(1.2);
  }
`;

export const ContactFomContainer = styled.div`
  width:${(props: any) => props.width ?? "100%"};
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
  border-radius: 23px;
  background: rgb(249, 249, 249);
  justify-content: ${(props: any) => props.justifyContent ?? "initial"};
  align-items:${(props: any) => props.alignItems ?? "initial"}
  box-sizing: border-box;
  padding: 40px;
  z-index: 10;
  height:${(props: any) => props.height ?? "auto"}
`;

export const Input = styled.input<CustomProps>`
  border: ${(props) => props.border ?? "1px solid #000000"};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  width: ${(props: any) => props.width ?? "100%"};
  min-width: 120px;
`;

export const ErrorText = styled.p`
  font-size: 11px;
  margin: 0px;
  color: red;
`;

export const TextArea = styled.textarea`
  border-radius: 8px;
  border: 1px solid #000000;
  padding: 8px 16px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  resize: none;
`;

export const CustomButton = styled(Button)`
  width: ${(props: any) => props.width ?? "200px"};
  border-radius: 8px;
  background: ${(props: any) => props.background ?? "#1F5D9E"};
  &:hover {
    background: ${(props: any) => props.background ?? "#1F5D9E"};
  }
`;

export const ButtonText = styled.span`
  color: ${(props) => props.color ?? "white"};
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
`;

export const ArrowBox = styled.div<CustomProps>`
  border: ${(props: any) => props.border ?? "3px solid #F9A51B"};
  background: ${(props: any) => props.background ?? "#007EC5"};
  height: 90px;
  width: 90px;
  border-radius: 0px 0px 40px;
  transform: ${(props: any) => props.transform ?? "rotate(225deg)"};
  display: flex;
  align-items: ${(props: any) => props.alignItems ?? "center"};
  justify-content: center;
  margin: ${(props: any) => props.margin ?? "0px"};
`;

export const ArrowBoxText = styled.p<CustomProps>`
  transform: ${(props: any) => props.transform ?? "rotate(-225deg)"};
  text-align: center;
  font-size: 12px;
  color: white;
  margin: 0px;
  line-height: 14px;
  padding: 0px 4px;
`;

export const FilledLine = styled.div`
  height: 9px;
  width: 100%;
  position: absolute;
  background: rgb(157, 157, 156);
  bottom: 5.5px;
  left: 23px;
  z-index: -3;
  border-radius: 0px 4px 4px 0px;
`;

export const ArrowWithTipBox = styled.div<CustomProps>`
  background: ${(props: any) => props.background ?? "rgb(249, 165, 27)"};
  height: 90px;
  width: 90px;
  border-radius: 35px 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 28px;
`;

export const ServicesContainer = styled.div.attrs(() => ({
  className:
    "position-relative d-flex flex-column justify-content-center align-items-center mt-md-3",
}))<CustomProps>`
  background: ${(props: any) => props.background ?? "rgb(244, 147, 23)"};
  height: 100px;
  width: 100px;
  border-radius: 8px;
  // margin: ${(props: any) => props.margin ?? "0px 0px 50px -80px"};
  transform: ${(props: any) => props.transform ?? "rotate(0deg)"};
  text-align: center;
`;

export const ServicesContainerTop = styled.div<CustomProps>`
  background: ${(props: any) => props.background ?? "#0D69B7"};
  height: 20px;
  width: 100%;
  position: absolute;
  top: 0px;
  border-radius: 8px 8px 0px 0px;
`;

export const FlowBorder = styled.div.attrs(() => ({
  className:
    "d-none d-sm-flex flex-column justify-content-center align-items-center",
}))`
  width: 4px;
  bottom: -56px;
`;

export const DropDownContent = styled.div<CustomProps>`
  display: none;
  position: absolute;
  top: 67px;
  left: ${(props) => props.left ?? "-40px"};
  background-color: white;
  min-width: ${(props: any) => props.minWidth ?? "160px"};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1;
  overflow: hidden;
  padding: 8px;
  ${CustomLink} {
    display: block;
    padding: 12px 16px;
    &:hover {
      background-color: rgba(18, 125, 194, 0.15);
      color: #127DC2;
    }
  }
`;

export const DropDown = styled.div<CustomProps>`
  display: inline-block;
  position: relative;
  padding: ${(props) => props.padding ?? "16px 0px;"};
  &:after {
    content: "";
    width: 100%;
    background-color: #004c74;
    border-radius: 4px;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.25s ease;
  }
  &:hover:after {
    transform: scaleX(1);
  }

  &:hover ${DropDownContent} {
    display: block;
  }
`;

export const PortfolioContainer = styled.div<CustomProps>`
  background-image: ${(props: any) =>
    props.url ??
    "linear-gradient(to right, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3))"};
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  @media (min-width: 768px) {
    min-width: 340px;
  }
`;

export const CounterContainer = styled(Col)`
  width: 274px;
  background: rgba(255, 255, 255, 0.50);
  height: 168px;
  display: flex;
  flex-direction : column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  text-align: center;
  color: #0082C6;
`;

export const ClearText = styled.span<CustomProps>`
  color: ${(props) => props.color ?? "#0082C6"};
  font-weight: ${(props) => props.fontWeight ?? "450"};
  display: inherit;
  &::first-letter {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const LocationDetailBox = styled.div`
  background: rgb(247, 247, 247);
  max-width: 380px;
  width: 100%;
  height: 200px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
`;

export const FooterText = styled.p`
  font-size: 16px;
  color: white;
  cursor: pointer;
  min-height: 25px;
`;
export const PortfolioBox = styled.div`
  width: 80%;
  height: 120px;
  margin-top: -60px;
  background: rgb(247, 247, 247);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 35px -15px,
    rgb(0 0 0 / 4%) 0px 10px 10px -5px;
`;
export const MemberBox = styled.div.attrs(() => ({
  className: "px-4 py-3 d-flex flex-column w-100",
}))`
  border: 2px solid rgba(0, 0, 0, 0.05);
`;

export const StrokeText = styled.h2`
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 100px;
  z-index: -1;
  -webkit-text-stroke: 1px rgba(5, 116, 255, 0.05);
  color: transparent;
  line-height: 0px;
`;
export const MemberCards = styled(Col)`
  background-color: #fff;
  margin: 0;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease;
  transform: translateY(0px);
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  min-width: 320px;
  &:hover {
    transform: translateY(-1px);
    ${MemberBox} {
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
        rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }
  }
`;

export const ColCards = styled(Col)`
  border-radius: 4px;
  background-color: transparent;
  position: relative;
  z-index: 0;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease;
  transform: translateY(0px);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 290px;
  &:hover {
    transform: translateY(-8px);
    ${PortfolioBox} {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
  }
`;

export const TestimonialImage = styled.img`
  position: absolute;
  width: 90%;
  height: 100%;
  bottom: 0px;
`;

export const TeamCard = styled.div<CustomProps>`
  box-shadow: ${(props) =>
    props.boxShadow ?? "rgb(100 100 111 / 20%) 0px 7px 29px 0px"};
  border-radius: 16px;
  max-width: 320px;
  width: 100%;
  margin: 16px 0px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
`;

export const FadeAway = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 30%;
  background: transparent;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 10%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const ProductBox = styled.div.attrs(() => ({
  className: "icon-bx-wraper style-3 m-b30",
}))``;

export const SubTitle = styled.h6.attrs(() => ({
  className: "sub-title  m-b15",
}))`
  // width: fit-content;
  // background : #f9a61c;
  // color : white;
  text-transform: capitalize;
`;

export const Title = styled.h2.attrs(() => ({
  className: "title m-t10",
}))`
  text-transform: capitalize;
`;

export const BlogCardImage = styled.div.attrs(() => ({
  className: "card__image",
}))<CustomProps>`
  background-image: ${(props) =>
    props.backgroundImage ??
    'url("https://images.unsplash.com/photo-1553787762-b5f5721f3270?ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80")'};
`;

export const BlogCardBody = styled.div.attrs(() => ({
  className: "card__body",
}))<CustomProps>``;

export const BlogCardArthor = styled.div.attrs(() => ({
  className: "card__author",
}))<CustomProps>``;

export const BlogCard = styled.article.attrs(() => ({
  className: "card",
}))<CustomProps>`
  cursor: pointer;
  &:hover {
    ${BlogCardBody} {
      transform: ${(props) => props.transformHeight ?? ""};
      transition-property: transform;
      transition-delay: 0;
    }
    ${BlogCardArthor} {
      transform: ${(props) => props.transformDistance ?? ""};
      transition-property: transform;
      transition-delay: 0;
    }
    ${BlogCardImage} {
      transform: ${(props) => props.transformFactor ?? ""};
      transition-property: transform;
      transition-delay: 0;
    }
  }
`;

export const CamerIconWrapper = styled.div`
  display: flex;
  width: 105px;
  height: 105px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  overflow: hidden;
`;

export const InputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const Label = styled.label`
  display: flex;
  cursor: pointer;
  background: transparent;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 44px;
  position: relative;
`;
export const UploadIcon = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
  background: rgb(0, 153, 255);
  align-items: center;
  border-radius: 5px;
  height: 41px;
  position: absolute;
  right: -3px;
  top: -0.5px;
`;
export const UploadContainer = styled.div`
  width: 85%;
  height: 39px;
  color: rgb(214, 217, 219);
  line-height: 39px;
  text-align: left;
  padding-left: 10px;
`;
