import { Col, Container, Form, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import {
  Input,
  TextArea,
  Title,
  Button,
  ParagraphText,
} from "../../Styles/StyleComponent";
import { useContext, useState } from "react";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { dealershipPost} from "../../Api/serviceapi";
import { falseNotify, trueNotify } from "../../App";
import { GlobalData } from "../../contextapi/ContextProvider";

const Dealership = () => {
    
    let {mobile,mobilelg} = useContext(GlobalData);
  
    /* api by rahul sharma */
    const [postData,setpostData] = useState({
      full_name : "",
      email : "",
      phone : "",
      designation : "",
      address : "",
      organization_name : "",
      organization_email : "",
      organization_phone : "",
      organization_address : "",
      targeted_school : "",
      intrest : "",
      marketing_mediums : "",
      description : ""
    });
    const handelChange = (e:any)=>{
        let name = e.target.name;
        let value = e.target.value;
        setpostData({...postData , [name]:value})
    }
  
    let postApi = async (e:any) =>{
      e.preventDefault();
      let res = await dealershipPost(postData);
      if(res.status===true){
        trueNotify('dealership form submitted.');
        setpostData({
            full_name : "",
            email : "",
            phone : "",
            designation : "",
            address : "",
            organization_name : "",
            organization_email : "",
            organization_phone : "",
            organization_address : "",
            targeted_school : "",
            intrest : "",
            marketing_mediums : "",
            description : ""
        });
      }
      else{
        falseNotify('dealership form not submitted.');
      }
     
    }
  
  
    return (
      <>
        <BackgroundContainer title={'Dealersip'} description={'We are seeking partners who share our interest in assisting us in our endeavor to transfor the education industry.'} > 
        <Container className="my-5">
     
        <Col xs={12}>
        <Row>
        <Col lg={5}>
          <Fade up delay={200}>
             <Title > <span style={{color:"#127DC2"}}>Dealersip Request</span></Title>
             <p>We are seeking partners who share our interest in assisting us in our endeavor to transfor the education industry.</p>
        </Fade>
        </Col>
        </Row>
      </Col>

      <Col xs={12}>
      <iframe style={{borderRadius : 16}} width="100%" height={mobile ? "250" : "450"} src="https://www.youtube.com/embed/KG_3K1zeQ4w?si=QaaP8d_b7ROP41l-" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </Col>
          {/* <Row className="m-0 mt-5 g-3">
            <Col lg={12}>
            <Fade up delay={100}>
              <Title>Provide the information below, and one of our experts will contact you within the next day.</Title>
            </Fade>
              <form onSubmit={postApi} className="mt-3">
                <Row className="g-3">
                  <Col>
                    <Fade up delay={100}>
                      <Input
                      name="full_name"
                      value={postData.full_name}
                      onChange={handelChange}
                        placeholder="Organization Name"
                      />
                    </Fade>
                  </Col>
                  <Col>
                    <Fade up delay={100}>
                      <Input
                        placeholder="Email"
                        name="email"
                      value={postData.email}
                        onChange={handelChange}
                      />
                    </Fade>
  
                    
                  </Col>
                </Row>
  
                <Row className="g-3 mt-0">
                  <Col>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Phone"
                        name="phone"
                        value={postData.phone}
  
                        onChange={handelChange}
                      />
                    </Fade>
                    
                  </Col>
                  <Col>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Designation"
                        name="designation"
                        value={postData.designation}
                        onChange={handelChange}
                      
                      />
                    </Fade>
  
                  </Col>
                </Row>
                <Row className="g-3 mt-0">
                  <Col xs={12}>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Address"
                        name="address"
                        value={postData.address}
  
                        onChange={handelChange}
                      />
                    </Fade>
                    
                  </Col>
                  <Col xs={12}>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Organization Name"
                        name="organization_name"
                        value={postData.organization_name}
  
                        onChange={handelChange}
                      />
                    </Fade>
                    
                  </Col>
                </Row>
                <Row className="g-3 mt-0">
                  <Col>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Organization Email"
                        name="organization_email"
                        value={postData.organization_email}
  
                        onChange={handelChange}
                      />
                    </Fade>
                    
                  </Col>
                  <Col>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Organization Phone"
                        name="organization_phone"
                        value={postData.organization_phone}
  
                        onChange={handelChange}
                      />
                    </Fade>
                    
                  </Col>
                </Row>
                <Row className="g-3 mt-0">
                  <Col>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Organization Address"
                        name="organization_address"
                        value={postData.organization_address}
  
                        onChange={handelChange}
                      />
                    </Fade>
                    
                  </Col>
                  <Col>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Targeted School per month"
                        name="targeted_school"
                        value={postData.targeted_school}
  
                        onChange={handelChange}
                      />
                    </Fade>
                    
                  </Col>
                </Row>

                <Row className="g-3 mt-0">
                  <Col md={6}>
                    <Fade up delay={200}>
                      <label>Please describe: your current interset level in becoming a <strong>Dynamic Technosoft</strong> dealer. </label>

                        <ul className="list-unstyled">
                            <li><input type="radio" name="intrest" onChange={handelChange} value={'high'} id="" className="me-2" /><span>Highly Intrested</span></li>
                            <li><input type="radio" name="intrest" onChange={handelChange} value={'moderately'} id="" className="me-2" /><span>Moderately Intrested</span></li>
                            <li><input type="radio" name="intrest" onChange={handelChange} value={'netural'} id="" className="me-2" /><span>Netural/Exploratory Intrested</span></li>
                            <li><input type="radio" name="intrest" onChange={handelChange} value={'slightly'} id="" className="me-2" /><span>Slightly Intrested</span></li>
                            <li><input type="radio" name="intrest" onChange={handelChange} value={'not intrest'} id="" className="me-2" /><span>Not Itrested at This Time</span></li>
                        </ul>
                    </Fade>
                    
                  </Col>
                  <Col md={6}>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Enter your marketing mediums"
                        name="marketing_mediums"
                        value={postData.marketing_mediums}
  
                        onChange={handelChange}
                      />
                    </Fade>
                    
                  </Col>
                </Row>

                <Fade up delay={300}>
                  <TextArea
                    className="mt-3 mb-2"
                    placeholder="Message"
                    name="description"
                    value={postData.description}
                        onChange={handelChange}
                    rows={5}
                  />
                </Fade>
                <Fade up delay={350}>
                  <Button
                    className="m-t10"
                    background="#0082C6"
                    padding="6px 0px"
                    style={{
                      width: "140px",
                      boxShadow: "0px 27px 40px 12px rgb(34 39 49 / 15%)",
                      borderRadius: "16px",
                    }}
                    type="submit"
                  >
                    <ParagraphText color="#fff">Send</ParagraphText>
                  </Button>
                </Fade>
              </form>
            </Col>
          </Row> */}
<Row className="m-0 mt-lg-5 mt-sm-4 mt-3 g-3">
  <Col lg={12} className="mx-auto">
    <div className="box d-flex align-items-center px-2 py-1  text-white" style={{background : '#0082c6',fontSize : mobile ? 16 :  20}}>
    <svg className="me-2" width={16} height={16} viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-2.4" y="-2.4" width="28.80" height="28.80" rx="0" fill="#f48636" strokeWidth={0}></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.42229 20.6181C10.1779 21.5395 11.0557 22.0001 12 22.0001V12.0001L2.63802 7.07275C2.62423 7.09491 2.6107 7.11727 2.5974 7.13986C2 8.15436 2 9.41678 2 11.9416V12.0586C2 14.5834 2 15.8459 2.5974 16.8604C3.19479 17.8749 4.27063 18.4395 6.42229 19.5686L8.42229 20.6181Z" fill='#f48636'></path> <path opacity="0.7" d="M17.5774 4.43152L15.5774 3.38197C13.8218 2.46066 12.944 2 11.9997 2C11.0554 2 10.1776 2.46066 8.42197 3.38197L6.42197 4.43152C4.31821 5.53552 3.24291 6.09982 2.6377 7.07264L11.9997 12L21.3617 7.07264C20.7564 6.09982 19.6811 5.53552 17.5774 4.43152Z" fill='#f48636'></path> <path opacity="0.5" d="M21.4026 7.13986C21.3893 7.11727 21.3758 7.09491 21.362 7.07275L12 12.0001V22.0001C12.9443 22.0001 13.8221 21.5395 15.5777 20.6181L17.5777 19.5686C19.7294 18.4395 20.8052 17.8749 21.4026 16.8604C22 15.8459 22 14.5834 22 12.0586V11.9416C22 9.41678 22 8.15436 21.4026 7.13986Z" fill="#f48636"></path> </g></svg> Provide the information below, and one of our experts will contact you within the next day.
    </div>
  </Col>


  <Col xs={12}>
    <Form onSubmit={postApi}> 
      <Row>
      <Col sm={6} className="my-lg-3 my-2">
        <Row>
          <Col sm={4} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>Full Name</span>
            <span>:</span>
          </Col>
          <Col sm={8}>
            <input name="full_name"
                        value={postData.full_name}
  
                        onChange={handelChange} type="text" className="w-100 border-0 p-2" style={{height : 40 , background : 'rgba(0,130,198,0.2)'}} placeholder="Enter your name" />
          </Col>
        </Row>
      </Col>

      <Col sm={6} className="my-lg-3 my-2">
        <Row>
          <Col sm={4} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>Email</span>
            <span>:</span>
          </Col>
          <Col sm={8}>
            <input name="email"
                        value={postData.email}
  
                        onChange={handelChange} type="email" className="w-100 border-0 p-2" style={{height : 40 , background : 'rgba(0,130,198,0.2)'}} placeholder="Enter your email" />
          </Col>
        </Row>
      </Col>

      <Col sm={6} className="my-lg-3 my-2">
        <Row>
          <Col sm={4} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>Phone</span>
            <span>:</span>
          </Col>
          <Col sm={8}>
            <input name="phone"
                        value={postData.phone}
  
                        onChange={handelChange} type="text" className="w-100 border-0 p-2" style={{height : 40 , background : 'rgba(0,130,198,0.2)'}} placeholder="Enter your phone number" />
          </Col>
        </Row>
      </Col>

      <Col sm={6} className="my-lg-3 my-2">
        <Row>
          <Col sm={4} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>Designation</span>
            <span>:</span>
          </Col>
          <Col sm={8}>
            <input name="designation"
                        value={postData.designation}
  
                        onChange={handelChange} type="text" className="w-100 border-0 p-2" style={{height : 40 , background : 'rgba(0,130,198,0.2)'}} placeholder="Enter your designation" />
          </Col>
        </Row>
      </Col>

      <Col sm={12} className="my-lg-3 my-2">
        <Row>
          <Col sm={2} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>Address</span>
            <span>:</span>
          </Col>
          <Col sm={10}>
            <input name="address"
                        value={postData.address}
  
                        onChange={handelChange} type="text" className="w-100 border-0 p-2" style={{height : 40 , background : 'rgba(0,130,198,0.2)'}} placeholder="Enter organization name" />
          </Col>
        </Row>
      </Col>

      <Col sm={12} className="my-lg-3 my-2">
        <Row>
          <Col sm={2} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>Organization Name</span>
            <span>:</span>
          </Col>
          <Col sm={10}>
            <input name="organization_name"
                        value={postData.organization_name}
  
                        onChange={handelChange} type="text" className="w-100 border-0 p-2" style={{height : 40 , background : 'rgba(0,130,198,0.2)'}} placeholder="Enter organization name" />
          </Col>
        </Row>
      </Col>
      <Col sm={6} className="my-lg-3 my-2">
        <Row>
          <Col sm={4} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>Organization Email</span>
            <span>:</span>
          </Col>
          <Col sm={8}>
            <input name="organization_email"
                        value={postData.organization_email}
  
                        onChange={handelChange} type="text" className="w-100 border-0 p-2" style={{height : 40 , background : 'rgba(0,130,198,0.2)'}} placeholder="Enter organization email" />
          </Col>
        </Row>
      </Col>

      <Col sm={6} className="my-lg-3 my-2">
        <Row>
          <Col sm={4} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>Organization Phone</span>
            <span>:</span>
          </Col>
          <Col sm={8}>
            <input name="organization_phone"
                        value={postData.organization_phone}
  
                        onChange={handelChange} type="text" className="w-100 border-0 p-2" style={{height : 40 , background : 'rgba(0,130,198,0.2)'}} placeholder="Enter organization phone" />
          </Col>
        </Row>
      </Col>

      
      <Col sm={6} className="my-lg-3 my-2">
        <Row>
          <Col sm={4} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>Organization Address</span>
            <span>:</span>
          </Col>
          <Col sm={8}>
            <input name="organization_address"
                        value={postData.organization_address}
  
                        onChange={handelChange} type="text" className="w-100 border-0 p-2" style={{height : 40 , background : 'rgba(0,130,198,0.2)'}} placeholder="Enter organization address" />
          </Col>
        </Row>
      </Col>

      <Col sm={6} className="my-lg-3 my-2">
        <Row>
          <Col sm={4} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>Targeted Schools per month</span>
            <span>:</span>
          </Col>
          <Col sm={8}>
            <input name="targeted_school"
                        value={postData.targeted_school}
  
                        onChange={handelChange} type="text" className="w-100 border-0 p-2" style={{height : 40 , background : 'rgba(0,130,198,0.2)'}} placeholder="Enter targeted No. of Schools" />
          </Col>
        </Row>
      </Col>

      <Col sm={6} className="my-lg-3 my-2">
        <Row>
          <Col sm={4} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>Please describe: your current interset level in becoming a <strong>Dynamic Technosoft</strong> dealer. </span>
            <span>:</span>
          </Col>
          <Col sm={8}>
          <ul className="list-unstyled">
                            <li><input type="radio" name="intrest" onChange={handelChange} value={'high'} id="" className="me-2" /><span>Highly Intrested</span></li>
                            <li><input type="radio" name="intrest" onChange={handelChange} value={'moderately'} id="" className="me-2" /><span>Moderately Intrested</span></li>
                            <li><input type="radio" name="intrest" onChange={handelChange} value={'netural'} id="" className="me-2" /><span>Netural/Exploratory Intrested</span></li>
                            <li><input type="radio" name="intrest" onChange={handelChange} value={'slightly'} id="" className="me-2" /><span>Slightly Intrested</span></li>
                            <li><input type="radio" name="intrest" onChange={handelChange} value={'not intrest'} id="" className="me-2" /><span>Not Itrested at This Time</span></li>
                        </ul>
                        
                        </Col>
        </Row>
      </Col>


      <Col sm={6} className="my-lg-3 my-2">
        <Row>
          <Col sm={4} className="d-flex justify-content-sm-between my-auto" style={{fontSize : 18}}>
            <span>What marketing mediums do you utilize?</span>
            <span>:</span>
          </Col>
          <Col sm={8}>
            <input name="marketing_mediums"
                        value={postData.marketing_mediums}
  
                        onChange={handelChange} type="text" className="w-100 border-0 p-2" style={{height : 40 , background : 'rgba(0,130,198,0.2)'}} placeholder="Enter your marketing mediums" />
          </Col>
        </Row>
      </Col>

      <Col sm={12} className="my-lg-3 my-2">
        <Row>
          <Col sm={2} className="d-flex justify-content-sm-between" style={{fontSize : 18}}>
            <span>Message</span>
            <span>:</span>
          </Col>
          <Col sm={10}>
            <textarea name="description"
                    value={postData.description}
                        onChange={handelChange} id=""  className="w-100 border-0 p-2" rows={5}  style={{background : 'rgba(0,130,198,0.2)'}} placeholder="What your message here"></textarea>
          </Col>
        </Row>
      </Col>

          <Col xs={12}>
          <Button
                    className="m-t10"
                    background="#0082C6"
                    padding="6px 0px"
                    style={{
                      width: "140px",
                      boxShadow: "0px 27px 40px 12px rgb(34 39 49 / 15%)",
                      borderRadius: "16px",
                    }}
                    type="submit"
                  >
                    <ParagraphText color="#fff">Send</ParagraphText>
                  </Button>
          </Col>
      </Row>


    </Form>
  </Col>
</Row>


        </Container>
  
  
        </BackgroundContainer>
      </>
    );
  };

export default Dealership
