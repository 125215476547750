import { Col, Container, Row } from "react-bootstrap";
import { strings } from "../../Api/Localization";
import academicErp from "../../Assets/coverImage/academicErp.png";
import sms from "../../Assets/products/sms.png";
import { Fade } from "react-reveal";
import {
  BR,
  ContainerTitle,
  ParagraphText,
  SubTitle,
  Title,
  Title40,
} from "../../Styles/StyleComponent";
import { CoverImage } from "../helper/CoverImage";
import { ArrowBoxLeft, YellowWave } from "./AcademicErp";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { GlobalData } from "../../contextapi/ContextProvider";
import { useContext } from "react";
import { ProductEvenItems, ProductOddItems } from "./ProductHelper";

import ASMMockup from "../../Assets/new/AMS Mockup.png";
import ASMModule1 from "../../Assets/new/asmmodule1.png";
import ASMModule2 from "../../Assets/new/asmmodule2.png";
import ASMModule3 from "../../Assets/new/asmmodule3.png";
import ASMModule4 from "../../Assets/new/asmmodule4.png";
import ASMModule5 from "../../Assets/new/asmmodule5.png";
import ASMModule6 from "../../Assets/new/asmmodule6.png";
import ASMModule7 from "../../Assets/new/asmmodule7.png";
import ASMModule8 from "../../Assets/new/asmmodule8.png";
import ASMModule9 from "../../Assets/new/asmmodule9.png";
import ASMModule10 from "../../Assets/new/asmmodule10.png";
import ASMModule11 from "../../Assets/new/asmmodule11.png";
import ASMModule12 from "../../Assets/new/asmmodule12.png";
import ASMModule13 from "../../Assets/new/asmmodule13.png";





export const DynamicAsm = () => {

  let {
    allpagesCollection,mobile
    } = useContext(GlobalData);


    
    const academicProducts = {
        Contacts_Management: {
        features: [
            "Centralized_contact_database" ,
            "Creation_and_management_of_Member_profile" ,
            "Contact_Segmentation" ,
            "Self_Registration" ,
        ],
      },
      Event_Management: {
        features: [
            "Online_Event_registration",
            "Attendee_Management",
            "Agenda_and_Schedule_Management",
            "Event_Analytics_and_Reporting",
            "Budgeting",
        ],
      },
      Members_Management: {
        features: [
            "Members_registration_details",
            "New_membership",
            "Centralized_Database",
            "Membership_Renewal",
            "Membership_Levels",
            "Analytics_Reporting",
        ],
      },
      SMS_Email: {
        features: [
            "Message_Composition_and_Customization",
            "Member_Notifications",
            "Personalization_of_SMS",
            "Event_Notifications",
            "Automated_Reminders",
        ],
      },
      Poll_Management: {
        features: [
            "Poll_creation",
            "Question_Design",
            "Member_participation",
            "Multiple_voting_options",
            "Online_voting",
            "Realtime_Results",
        ],
      },
      Fundraising_Management: {
        features: [
            "Donation_type",
            "Donation_list",
            "Donation_Receipt",
            "Confirmation_of_mail",
            "Send_reminder_to_members_for_contribution",
        ],
      },
      Budget_Management: {
        features: [
            "Budget_Creation",
            "Budget_allocation",
            "Expenses_tracking_against_budget",
            "Financial_reporting",
        ],
      },
      AGM: {
        features: [
            "AGM_planning",
            "Member_registration",
            "Meeting",
            "Minutes_Management",
            "Online_voting_AGAIN",
        ],
      },
      Document_Management: {
        features: [
            "Document_Repository",
            "Document_Search",
            "Access_Controls_and_Permissions",
            "Document_Expiration_and_Alerts",
            "Document_Analytics_and_Reporting",
        ],
      },
      Accounting: {
        features: [
            "Ledger_and_ledger_group_creation",
            "Receipts_and_payments",
            "Reports",
            "Journal_voucher_daybook_and_contra",
            "Receipts_and_payments_AGAIN",
            "Bank_reconciliation",
        ],
      },
      Inventory: {
        features: [
            "Product_management",
            "Opening_stock",
            "Purchase_and_sales",
            "Supplier_management",
            "Reports_AGAIN",
        ],
      },
      HRM_Payroll: {
        features: [
            "Employee_Information_Management",
            "Recruitment_and_Onboarding",
            "Time_and_Attendance_Tracking",
            "Payroll_Calculation_and_Processing",
            "Reporting_and_Analytics",
        ],
      },
      Website: {
        features: [
            "Website_Creation_and_Design",
            "Content_Management",
            "Membership_Directory",
            "Event_Listing_and_Registration",
            "Website_Analytics"
        ],
      }
    }
    const newModules = [
      {
        id: 0,
        icon : ASMModule1,
        title : "Contacts Management",
        para : strings.getString("dynamic_asm_moduel_1_descripiton"),
        products: academicProducts.Contacts_Management
      },

      {
        id: 0,
        icon : ASMModule2,
        title : "Event Management",
        para : strings.getString("dynamic_asm_moduel_2_descripiton"),
        products: academicProducts.Event_Management
      },

      {
        id: 0,
        icon : ASMModule3,
        title : "Members Management",
        para : strings.getString("dynamic_asm_moduel_3_descripiton"),
        products: academicProducts.Members_Management
      },

      {
        id: 0,
        icon : ASMModule4,
        title : "SMS and Email",
        para : strings.getString("dynamic_asm_moduel_4_descripiton"),
        products: academicProducts.SMS_Email
      },

      {
        id: 0,
        icon : ASMModule5,
        title : "Poll Management",
        para : strings.getString("dynamic_asm_moduel_5_descripiton"),
        products: academicProducts.Poll_Management
      },

      {
        id: 0,
        icon : ASMModule6,
        title : "Fundraising Management and Donation Management",
        para : strings.getString("dynamic_asm_moduel_6_descripiton"),
        products: academicProducts.Fundraising_Management
      },

      {
        id: 0,
        icon : ASMModule7,
        title : "Budget Management",
        para : strings.getString("dynamic_asm_moduel_7_descripiton"),
        products: academicProducts.Budget_Management
      },

      {
        id: 0,
        icon : ASMModule8,
        title : "AGM (Annual General Meeting)",
        para : strings.getString("dynamic_asm_moduel_8_descripiton"),
        products: academicProducts.AGM
      },

      {
        id: 0,
        icon : ASMModule9,
        title : "Document Management",
        para : strings.getString("dynamic_asm_moduel_9_descripiton"),
        products: academicProducts.Document_Management
      },

      {
        id: 0,
        icon : ASMModule10,
        title : "Accounting",
        para : strings.getString("dynamic_asm_moduel_10_descripiton"),
        products: academicProducts.Accounting
      },

      {
        id: 0,
        icon : ASMModule11,
        title : "Inventory",
        para : strings.getString("dynamic_asm_moduel_11_descripiton"),
        products: academicProducts.Inventory
      },

      {
        id: 0,
        icon : ASMModule12,
        title : "HRM and Payroll",
        para : strings.getString("dynamic_asm_moduel_12_descripiton"),
        products: academicProducts.HRM_Payroll
      },
      {
        id: 0,
        icon : ASMModule13,
        title : "Website",
        para : strings.getString("dynamic_asm_moduel_13_descripiton"),
        products: academicProducts.Website
      },
    ]


  return (
    <>
      {/* <CoverImage image={academicErp} path="web_sms_system" type="products" /> */}

      <BackgroundContainer title={allpagesCollection.product_5_title || strings.getString("web_sms_system")} description={''} > 



      <Container className="my-5 ">
      <Row className="py-4">
        
      <Col xs={12} style={{marginBottom : mobile ? 20: 60}}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>AMS Software</span></Title>
           <p>Use our feature-rich Association Management Software (AMS) to completely revamp the way you run your association. Increase member involvement, simplify administrative work, and easily cultivate a vibrant community.</p>
      </Fade>
      </Col>
      </Row>
    </Col>

          <Col lg={8} className="bg-grey order-2 order-sm-1">
          <Fade up delay={200}>
           <Title40 > <span style={{color:"#233d63"}}>AMS Software Can Help You Manage Your Organization Better</span></Title40>
      </Fade>
          <Fade up delay={200}>   <ParagraphText color="#000">

          <div className="blogParagraph">A state-of-the-art association management software with a number of modules that improve and expedite different areas of management, empowering organizations. All of these things are guaranteed by our integrated system: efficiency, transparency, and teamwork.</div>

          </ParagraphText>
            </Fade>
          </Col>
          <Col
            lg={4}
            className=" d-flex justify-content-lg-end justify-content-center  order-1 order-sm-2"
          >
            <img src={ASMMockup} alt="" className="img-fluid animatedUp" style={{zIndex: '2' , objectFit : 'contain'}} />
          </Col>
        </Row>
      </Container>

      <Container  className="mb-5">
        <Row>
        {newModules?.map((val,index)=>{
            return(<>

<Col xl={6} className="my-lg-3 my-3" key={index}>
              <div className="cardsServices-new">
                <h4 className="mt-2 border-bottom w-100 p-3"> <img src={val.icon} style={{height : '30px' , width : '30px'}} alt="" className="me-2" /> {val.title}</h4>
                <p className="px-3">{val.para}</p>
                <ProductOddItems products={val.products} />
               {/* {index%2 === 0 ? <ProductOddItems products={val.products} /> : <ProductEvenItems products={val.products} />} */}
              </div>
            </Col>


            </>)
          }) }
        </Row>
      </Container>
      </BackgroundContainer>
    </>
  );
};
