import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import BackgroundContainer from "../../utils/BackgroundContainer";
import {
  Input,
  ParagraphText,
  TextArea,
  Title,
} from "../../Styles/StyleComponent";
import { trueNotify } from "../../App";

const Account = () => {
  const [formData, setFormData] = useState({
    username: "",
    reason: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate form inputs
    const validationErrors = {};
    if (!formData.username) {
      validationErrors.username = "Username is required";
    }

    if (!formData.password) {
      validationErrors.password = "Password is required";
    }

    // If there are validation errors, set the state and prevent form submission
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setFormData({
      username: "",
      reason: "",
      password: "",
    });

    // Clear errors state if form submission is successful
    setErrors({});

    trueNotify("Account deleted successfully.");

    // If there are no validation errors, you can submit the form data
    // For example, you can make an API call here to submit the form data
    // console.log("Form submitted successfully:", formData);
  };

  return (
    <BackgroundContainer>
      <Container className="mt-5 mb-5  ">
        <div>
          <Title>Delete Account</Title>
          <hr />
          <p>
            Deleting your account is permanent .
            <span className="mx-1" style={{ fontWeight: "bold" }}>
              All your data will be wiped out immediately
            </span>
            <br />
            and you won't be able to get it back.
          </p>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label style={{ fontWeight: "bold" }}>Username :</label>
                  <Input
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                  />
                  {errors.username && (
                    <span style={{ color: "red" }}>{errors.username}</span>
                  )}
                </div>
                <div className="mb-3">
                  <label style={{ fontWeight: "bold" }}>Password :</label>
                  <Input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />

                  {errors.password && (
                    <span style={{ color: "red" }}>{errors.password}</span>
                  )}
                </div>
                <div className="mb-3">
                  <label style={{ fontWeight: "bold" }}>
                    Reason for deletion (optional)
                  </label>
                  <TextArea
                    rows={5}
                    type="text"
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                  />
                </div>
                <Button
                  className="m-t10 mb-3"
                  background="#0082C6"
                  padding="6px 0px"
                  style={{
                    width: "140px",
                    boxShadow: "0px 27px 40px 12px rgb(34 39 49 / 15%)",
                    borderRadius: "16px",
                  }}
                  type="submit"
                >
                  <ParagraphText color="#fff">Delete</ParagraphText>
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </Container>
    </BackgroundContainer>
  );
};

export default Account;
