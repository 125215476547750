import { useEffect, useRef } from "react";
import { Box3D, Circle, DotIcon } from "../Assets/new/animation/AnimatedIcon";
import triangleImage from '../Assets/new/animation/triangle3d.png'
import { Nav, Col as Cols } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { keyframes } from "styled-components";


export const Animation = () => {
    let container = useRef(null);
    let target = useRef(null);
  
    useEffect(() => {
      if (container?.current?.offsetHeight && target?.current?.offsetHeight) {
        floatHead();
      }
    });
  
    function makeNewPosition() {
      let containerVspace =
          container.current?.offsetHeight - target.current?.offsetHeight,
        containerHspace =
          container.current?.offsetWidth - target.current?.offsetWidth,
        newX = Math.floor(Math.random() * containerVspace),
        newY = Math.floor(Math.random() * containerHspace);
      return [newX, newY];
    }
  
    function velocity(prev, next) {
      let x = Math.abs(prev[1] - next[1]),
        y = Math.abs(prev[0] - next[0]),
        larger = x > y ? x : y,
        speedModifier = 0.02,
        speed = Math.ceil(larger / speedModifier);
      return speed;
    }
  
    function floatHead() {
      let newPos = makeNewPosition(),
        oldTop = target.current?.offsetTop,
        oldLeft = target.current?.offsetLeft;
      if (target.current) {
        target.current.animate(
          [
            { top: oldTop + "px", left: oldLeft + "px" },
            { top: newPos[0] + "px", left: newPos[1] + "px" },
          ],
          {
            duration: velocity([oldTop, oldLeft], newPos),
            fill: "forwards",
          }
        ).onfinish = function () {
          floatHead();
        };
      }
    }
  
    return (
      <>
        <div id="containera" ref={container}>
          <img
            ref={target}
            src={triangleImage}
            id="target"
            alt=""
            height="50px"
            width="50px"
          ></img>
        </div>
  
        <div className="main d-none d-md-block">
          <div className="circle"></div>
        </div>
        <AnimatedBox left="50%" transform="rotate(45deg)" display="none">
          <Box3D />
        </AnimatedBox>
        <AnimatedBox left="80%" transform="rotate(180deg)" display="none">
        <AnimatedCircle />
        </AnimatedBox>
        <AnimatedBox left="90%" bottom="10%" display="none">
          <Box3D size="20px" />
        </AnimatedBox>
        <AnimatedBox left="60%" transform="rotate(180deg)" display="none">
        <AnimatedCircle />
        </AnimatedBox>
        <AnimatedBox top="25%" right="5%" id="target">
          <DotIcon />
        </AnimatedBox>
        <AnimatedCircle />

        <svg className="tianglespin" style={{position: 'absolute' , top : 200 , right : 300} } xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74" fill="none">
  <g opacity="0.86" filter="url(#filter0_f_1423_10350)">
    <path d="M46.5661 23.3593L37.594 24.8168L35.4166 59.9615L44.3347 58.5868L46.5661 23.3593Z" fill="url(#paint0_linear_1423_10350)"/>
    <path d="M12.6973 36.7588L3.72519 38.2163L39.0436 69.4057L48.0157 67.9483L12.6973 36.7588Z" fill="url(#paint1_linear_1423_10350)"/>
    <path d="M51.1669 14.6845L42.1948 16.142L3.72524 38.2163L12.6973 36.7588L51.1669 14.6845Z" fill="url(#paint2_linear_1423_10350)"/>
    <path d="M21.0926 37.9922L44.3349 58.5873L44.3889 58.5046L48.0154 67.9487L12.697 36.7591L21.0926 37.9922Z" fill="url(#paint3_linear_1423_10350)"/>
    <path d="M48.0164 67.949L44.3899 58.5049L46.5673 23.3602L46.4306 23.3889L51.1135 14.7678L48.0164 67.949Z" fill="url(#paint4_linear_1423_10350)"/>
    <path d="M51.113 14.7675L46.43 23.3886L21.0931 37.9922L12.6974 36.7591L51.167 14.6847L51.113 14.7675Z" fill="url(#paint5_linear_1423_10350)"/>
  </g>
  <defs>
    <filter id="filter0_f_1423_10350" x="0.72522" y="11.6846" width="53.4418" height="60.7212" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_1423_10350"/>
    </filter>
    <linearGradient id="paint0_linear_1423_10350" x1="39.7229" y1="58.2967" x2="39.7229" y2="24.0958" gradientUnits="userSpaceOnUse">
      <stop stop-color="#379ADA"/>
      <stop offset="1" stop-color="#9ADFCA"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1423_10350" x1="35.5608" y1="61.0022" x2="11.77" y2="35.9288" gradientUnits="userSpaceOnUse">
      <stop offset="0.192708" stop-color="#379ADA"/>
      <stop offset="1" stop-color="#BCFF92"/>
    </linearGradient>
    <linearGradient id="paint2_linear_1423_10350" x1="5.29786" y1="39.5816" x2="72.0171" y2="0.0468021" gradientUnits="userSpaceOnUse">
      <stop stop-color="#63C5A6"/>
      <stop offset="1" stop-color="#379ADA"/>
    </linearGradient>
    <linearGradient id="paint3_linear_1423_10350" x1="46.916" y1="63.3588" x2="17.099" y2="38.3127" gradientUnits="userSpaceOnUse">
      <stop stop-color="#379ADA"/>
      <stop offset="1" stop-color="#99FFDF"/>
    </linearGradient>
    <linearGradient id="paint4_linear_1423_10350" x1="46.4305" y1="58.8706" x2="48.433" y2="25.7147" gradientUnits="userSpaceOnUse">
      <stop stop-color="#AAF1DB"/>
      <stop offset="1" stop-color="#379ADA"/>
    </linearGradient>
    <linearGradient id="paint5_linear_1423_10350" x1="21.4041" y1="35.0555" x2="48.8238" y2="18.4006" gradientUnits="userSpaceOnUse">
      <stop stop-color="#379ADA"/>
      <stop offset="1" stop-color="#E4FFF7"/>
    </linearGradient>
  </defs>
</svg>

<svg  className="squarespin" style={{position: 'absolute' , top : 50 , right : 50} } xmlns="http://www.w3.org/2000/svg" width="70" height="75" viewBox="0 0 70 75" fill="none">
  <g filter="url(#filter0_f_1423_10346)">
    <path d="M59.0652 55.6404L27.5156 71.5395L39.3145 46.3614L66.8697 30.7097L59.0652 55.6404Z" fill="url(#paint0_linear_1423_10346)"/>
    <path d="M3 41.8546L14.1208 19.2991L39.1558 46.7484L27.523 71.5472L3 41.8546Z" fill="url(#paint1_linear_1423_10346)"/>
    <path d="M43.0101 3L14.1123 19.3044L38.9005 46.8761L66.8755 30.7412L43.0101 3Z" fill="url(#paint2_linear_1423_10346)"/>
  </g>
  <defs>
    <filter id="filter0_f_1423_10346" x="0" y="0" width="69.8755" height="74.5471" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_1423_10346"/>
    </filter>
    <linearGradient id="paint0_linear_1423_10346" x1="47.1927" y1="30.7097" x2="47.1927" y2="71.5395" gradientUnits="userSpaceOnUse">
      <stop stop-color="#379ADA"/>
      <stop offset="0.9999" stop-color="#167CBD"/>
      <stop offset="1" stop-color="#5A9D2F"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1423_10346" x1="21.0277" y1="19.5271" x2="21.0277" y2="71.5473" gradientUnits="userSpaceOnUse">
      <stop stop-color="#379ADA"/>
      <stop offset="1" stop-color="#3B7194"/>
    </linearGradient>
    <linearGradient id="paint2_linear_1423_10346" x1="40.4911" y1="3" x2="40.4911" y2="46.8761" gradientUnits="userSpaceOnUse">
      <stop stop-color="#379ADA"/>
      <stop offset="1" stop-color="#4B6C82"/>
    </linearGradient>
  </defs>
</svg>

      </>
    );
  };



export const HeaderBackground = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  z-index: -1;
  position: absolute;
  justify-content: end;
  @media (max-width: 768px) {
    background: linear-gradient(313.08deg, #097a57 -22.39%, #73bf43 112.07%);
  }
  @media (max-width: 1024px) {
    min-height: ${(props) => props.minHeight ?? "50%"};
    height: 75vh;
  }
`;

export const TitleText = styled.h3`
  font-weight: 600;
  font-size: 40px;
  color: ${(props) => props.color ?? "#097A57"};
  line-height: 45px;
  cursor: default;
`;

export const SecondaryText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  color: ${(props) => props.color ?? "#097A57"};
  cursor: default;
`;

export const PrimaryText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  color: ${(props) => props.color ?? "#097A57"};
  line-height: 28px;
  cursor: default;
`;

export const LinkText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-transform: ${(props) => props.textTransform ?? "capitalize"};
  margin: 0px;
  cursor: pointer;
  color: ${(props) => props.color ?? "#fff"};
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const CustomLink = styled(NavLink)`
  text-decoration: none;
  height: 35px;
  display: flex;
  // justify-content: center;
  align-items: center;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    background-color: ${(props) => props.color ?? "#fff"};
    border-radius: 4px;
    height: 3px;
    position: absolute;
    bottom: ${(props) => props.bottom ?? "10px"};
    left: 0;
    transform: ${(props) => props.transform ?? "scaleX(0)"};
    transform-origin: left;
    transition: transform 0.25s ease;
  }
  &:hover:after {
    transform: scaleX(1);
  }
  &:hover {
    ${LinkText} {
      color: ${(props) => props.color ?? "#fff"};
    }
  }
`;

export const Button = styled.button`
  border: ${(props) => props.border ?? "none"};
  box-sizing: border-box;
  border-radius: 8px;
  background: ${(props) => props.background ?? "transparent"};
  padding: ${(props) => props.padding ?? "0px 12px"};
  color: ${(props) => props.color ?? "white"};
`;

export const Line = styled.span`
  display: block;
  width: 30px;
  height: 3px;
  background: black;
  margin-block: 10px;
  border-radius: 4px;
  transition: transform 0.25s;
  opacity: ${(props) => props.opacity ?? "0.25"};
  transform: ${(props) => props.transform ?? "translateY(0px) rotate(0deg)"};
`;

export const NavContainer = styled(Nav).attrs(() => ({
  className: "justify-content-between",
}))`
  width: 79%;
  @media (max-width: 1172px) {
    width: 89%;
  }
  @media (max-width: 992px) {
    position: absolute;
    top: 7px;
    background: #f1f1f1;
    z-index: 2;
    width: 100%;
    padding: 16px;
    border-radius: 0px 0px 8px 8px;
  }
`;

export const ErrorText = styled.p`
  font-size: 11px;
  margin: 0px;
  color: red;
  cursor: default;
`;

export const GradientBackground = styled.div`
  min-width: 100vw;
  // @media (max-width: 768px) {

  //   background: linear-gradient(313.08deg, #097a57 -22.39%, #73bf43 112.07%);
  // }
`;

export const ImageWithGradient = styled.div.attrs(() => ({
  className: "d-flex justify-content-between align-items-center",
}))`
  background-image: ${(props) =>
    props.image
      ? `linear-gradient(94.62deg,rgba(9, 122, 87, 0.89) 0%,rgba(115, 191, 67, 0.89) 102.72%),url(${props.image})`
      : "linear-gradient(94.62deg,rgba(9, 122, 87, 0.89) 0%,rgba(115, 191, 67, 0.89) 102.72%)"};
  width: 100%;
  background-repeat: no-repeat;
  min-height: 300px;
`;

export const BoldText = styled.h1`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? "800"};
  font-size: ${(props) => props.fontSize ?? "40px"};
  color: ${(props) => props.color ?? "#ffffff"};
  cursor: default;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 900;
  font-size: 2.5rem;
  letter-spacing: ${(props) => props.letterSpacing ?? "0px"};
  color: ${(props) => props.color ?? "#097a57"};
  cursor: default;

  @media (max-width: 1024px) {
    font-size: 2.2rem;
    letter-spacing: 0px;
  }
  @media (max-width: 768px) {
    font-size: 1.7rem;
  }
  @media (max-width: 576px) {
    font-size: 1.6rem;
    letter-spacing: 0px;
  }
`;
export const SubTitle = styled.h3`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? "500"};
  font-size: 30px;
  color: ${(props) => props.color ?? "#fff"};
  margin: 0;
  line-height: 30px;
  cursor: default;
`;

export const Typography = styled.p`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? "400"};
  font-size: ${(props) => props.fontSize ?? "16px"};
  text-align: ${(props) => props.textAlign ?? "start"};
  color: ${(props) => props.color ?? "#000000"};
  margin: 0px;
  cursor: default;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

export const EllipseTypo = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lineClamp ?? "3"};
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? "400"};
  font-size: ${(props) => props.fontSize ?? "16px"};
  text-align: ${(props) => props.textAlign ?? "left"};
  color: ${(props) => props.color ?? "#000000"};
  margin: 0px;
  cursor: default;
`;
const scale = keyframes`
   0% {
       transform: scale(1.0);
           
        }
   100% {
           transform: scale(2.0);
            
        }
`;

export const BlinkAnimation = styled.div`
  width: 2px;
  height: 2px;
  background: linear-gradient(180deg, #38cc9e 0%, #cdffad 100%);
  border-radius: 50%;
  position: absolute;
  display: none;
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  z-index: 5;
  animation: ${scale} 1s ease-out infinite;
`;

export const Col = styled(Cols)`
  min-width: ${(props) => props.minwidth ?? "320px"};
  @media (max-width: 1024px) {
    min-width: ${(props) => props.minwidth ?? "320px"};
  }
  @media (max-width: 568px) {
    min-width: ${(props) =>
      props.minwidth === "495px" ? "320px" : props.minwidth ?? "320px"};
  }
  &:hover {
    ${BlinkAnimation} {
      display: block;
    }
  }
`;

export const ModuleBox = styled.div`
  overflow: hidden;
  border-radius: 0px 50px;
  background: ${(props) =>
    props.background
      ? "linear-gradient(138.53deg,rgb(9, 122, 87) 0%,rgb(115, 191, 67) 101.5%)"
      : " #eceeed"};
  position: relative;
  color: #000;
  min-height: ${(props) => props.minHeight};
  &:before {
    content: "";
    position: absolute;
    background: ${(props) => (props.background ? "#097a57" : "#eceeed")};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    top: 0px;
    width: 100%;
    height: 70px;
  }

  ${SubTitle} {
    color: ${(props) => (props.background ? "#fff" : "#097A57")};
  }
  ${EllipseTypo} {
    color: ${(props) => (props.background ? "#fff" : "#000")};
  }
  ${Typography} {
    color: ${(props) => (props.background ? "#fff" : "#097A57")};
  }
`;

export const OfferContainer = styled.div.attrs(() => ({
  className: "d-flex justify-content-center align-items-center flex-column",
}))`
  background: ${(props) =>
    props.background
      ? "linear-gradient(131.58deg, #097a57 -1.47%, #73bf43 97.29%)"
      : "#ECEEED"};
  box-shadow: ${(props) =>
    props.boxShadow ?? "0px 4px 4px rgba(0, 0, 0, 0.25)"};
  min-height: 212px;
  ${SubTitle} {
    color: ${(props) => (props.background ? "#fff" : "#097A57")};
  }
`;

export const ColCenter = styled(Col).attrs(() => ({
  className: "d-flex align-items-center flex-column",
}))`
  justify-content: center;
  minwidth: ${(props) => props.minWidth ?? ""};
`;

export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  top: 40px;
  left: -40px;
  background-color: #f1f1f1;
  min-width: ${(props) => props.minWidth ?? "160px"};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  z-index: 1;
  overflow: hidden;
`;

export const DropDown = styled.div`
  display: inline-block;
  position: relative;
  padding: ${(props) => props.padding ?? "16px 0px;"};
  &:after {
    content: "";
    width: 100%;
    background-color: #004c74;
    border-radius: 4px;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.25s ease;
  }
  &:hover:after {
    transform: scaleX(1);
  }
  &:hover ${DropDownContent} {
    display: block;
  }
`;

export const DropDownLink = styled.p`
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 18px;
  color: ${(props) => props.color ?? "black"};
  display: flex;
  padding: 12px 0px;
  margin: 0px;
  height: 35px;
  cursor: pointer;
  font-weight: 450;
`;

export const MarkerUl = styled.ul`
  padding-left: ${(props) => props.paddingLeft ?? "1rem"};
  margin-top: 12px;
  color: ${(props) => props.color ?? "#fff"};
`;

export const DynamicBackgroundBox = styled.div`
  background: ${(props) => props.background ?? "rgb(33, 172, 101)"};
  border-radius: 0px 40px;
  min-height: ${(props) => props.minHeight ?? "380px"};
  margin-top: ${(props) => props.marginTop ?? "0px"};
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

export const ModuleContainer = styled.div.attrs(() => ({
  className: "d-flex justify-content-center align-items-center ",
}))`
  height: 100%;
`;

export const ContactFomContainer = styled.div`
  width:${(props) => props.width ?? "100%"};
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
  border-radius: 23px;
  background: rgb(249, 249, 249);
  justify-content: ${(props) => props.justifyContent ?? "initial"};
  align-items:${(props) => props.alignItems ?? "initial"}
  box-sizing: border-box;
  padding: 2rem;
  z-index: 10;
  height:${(props) => props.height ?? "auto"}
`;

export const Input = styled.input`
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  width: ${(props) => props.width ?? "100%"};
`;

export const TextArea = styled.textarea`
  border-radius: 12px;
  border: 1px solid #000000;
  padding: 8px 16px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  resize: none;
`;

export const InfoBox = styled.div`
  background: #f6f6f6;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: ${(props) => props.borderRadius ?? "20px"};
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? "center"};
  align-items: ${(props) => props.justifyContent ?? "center"};
  flex-direction: column;
  padding: ${(props) => props.padding ?? "16px"};
  min-height: 230px;
`;

export const Span = styled.span`
  font-weight: ${(props) => props.fontWeight ?? "400"};
  font-size: ${(props) => props.fontSize ?? "16px"};
  text-align: ${(props) => props.textAlign ?? ""};
  color: ${(props) => props.color ?? "#000000"};
`;

export const AnimatedBox = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  transform: ${(props) => props.transform};
  @media (max-width: 645px) {
    display: ${(props) => props.display ?? "block"};
  }
`;

const rotate = keyframes`
   0% {
    transform: translate(0,0);
  }
  33% {
    transform: translate(-75px, -150px)  ;
  }
  66% {
    transform: translate(75px, -150px);
  }
  100% {
    transform: translate(0,0);
  }
`;
const circle = keyframes`
  0% {
    transform: rotate(0deg) translate(-105px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-105px) rotate(-360deg);
  }
`;

export const AnimatedCircle = styled.div`
  width: 15px;
  height: 15px;
  background: linear-gradient(180deg, #38cc9e 0%, #127dc2 100%);
  border-radius: 50%;
  position: absolute;
  top: ${(props) => props.top ?? "20%"};
  left: ${(props) => props.left ?? "20%"};
  bottom: ${(props) => props.left ?? "unset"};
  right: ${(props) => props.right ?? "unset"};
  animation: ${rotate} 20s infinite linear;
  z-index: -1;
`;

export const BorderCircle = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  z-index: ${(props) => props.zIndex ?? "-1"};
  animation: ${circle} 22s linear infinite;
`;

export const Box360 = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? "center"};
  align-items: center;
  width: ${(props) => props.width ?? "100%"};
  position: ${(props) => props.position ?? "static"};
  margin-bottom: ${(props) => props.marginBottom ?? "0px"};
  bottom: ${(props) => props.bottom ?? "unset"};
  top: ${(props) => props.top ?? "unset"};
  @media (max-width: 480px) {
    width: ${(props) =>
      props.width === "91%" || !props.width ? "100%" : "70%"};
    top: ${(props) => (props.top === "-100px" ? "-25px" : "unset")};
    bottom: ${(props) => (props.bottom ? "55px" : "unset")};
  }
`;

export const ClearText = styled.span`
  color: ${(props) => props.color ?? "#0082C6"};
  font-weight: ${(props) => props.fontWeight ?? "500"};
  display: inherit;
  &::first-letter {
    font-size: 18px;
    font-weight: 600;
  }
`;

//
export const CamerIconWrapper = styled.div`
  display: flex;
  width: 105px;
  height: 105px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  overflow: hidden;
`;

export const InputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const Label = styled.label`
  display: flex;
  cursor: pointer;
  background: transparent;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 44px;
  position: relative;
`;
export const UploadIcon = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
  background: rgb(0, 153, 255);
  align-items: center;
  border-radius: 5px;
  height: 41px;
  position: absolute;
  right: -3px;
  top: -0.5px;
`;
export const UploadContainer = styled.div`
  width: 85%;
  height: 39px;
  color: rgb(214, 217, 219);
  line-height: 39px;
  text-align: left;
  padding-left: 10px;
`;
