import { Card, Col, Container, Row } from "react-bootstrap";
import { strings } from "../../Api/Localization";
import dotImage from "../../Assets/dotImage.png";
import { ProductSliders } from "../Sliders/ProductsSlider";
import { HomeBlogsSliders, ServiceSliders} from "../Sliders/ServiceSlider";
import { useContext } from "react";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import Headers from "../Sliders/HeaderSlider";
import "swiper/css/navigation";
import { Counter } from "../helper/Counter";
import { OurClient } from "../Clients/OurClient";
import {
  Button,
  SubTitle,
  Title,
  EllipsisText,
} from "../../Styles/StyleComponent";
import SwiperCore, {  Controller, Thumbs } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { Mousewheel, Keyboard } from "swiper";

import videoThumb from "../../Assets/new/videosliderthumbnail.png";
import { GlobalData } from "../../contextapi/ContextProvider";
SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);





export const ClientTestimonial = ({customstyle}:any) =>{

  let {
    testmonialCollection,mobile
    } = useContext(GlobalData);

  return(
    <>

{testmonialCollection.length> 0  ? 
<div className="position-relative my-5" style={customstyle}>
        <Container className="py-5 mt-5 customSliderContainer">
          <Row className="align-items-center g-3">
      
            <Col className="d-flex flex-column text-white" lg={6}>

              <Fade up delay={100}>
                <p>Testimonial</p>
                <Title>See the Testimonials of Our Happy Customers
                </Title>
              </Fade>
              <Fade up delay={200}>
                <p>Enter the domain of customer happiness as we reveal a tapestry of testimonies that tells a story of achievement, greatness, and trust. Hear from people who have used our products or services and can attest to their impact in their own words. These testimonials offer a glimpse into the real value we deliver to our clients, ranging from positive evaluations to motivational success tales. Discover the actual extent of our dedication to going above and beyond by traveling with us through the words of those who have chosen to share their positive experiences.</p>
            
              </Fade>
            </Col>


            <Col lg={6} className="ms-auto">
              <Fade left delay={200}>
              <Swiper
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        spaceBetween={10}
        modules={[Pagination, Mousewheel, Keyboard]}
        className="mySwiper" >

          {
            testmonialCollection?.sort((a:any, b:any) => a.orderAt - b.orderAt)?.map((val:any,index:any)=>{
              return(<>
              <SwiperSlide className="px-3 py-5" key={index}>
           <Card className="box w-100 p-lg-2 p-0 text-black" style={{borderRadius: "20px",
background:" #FAFAFA",
height : 300,
boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset"}}>
          {val.content_type==="content" ?   <Card.Header className="border-0 bg-transparent d-flex justify-content-between">
              <div className="profile d-flex flex-wrap pt-md-0 pt-3 align-items-center">
                <img src={val.image} alt="" className="rounded-4 me-2"  style={{height : '50px' , width : '50px',borderRadius: "10px", objectFit : 'cover' , border : '2px solid #FF7350'}} />
                <ul className="list-unstyled">
                  <li style={{
                    color: "#125875",
                    fontWeight: "600"
                  }}>{val.testmonial_name}</li>
                  <li> <EllipsisText lineClamp={"1"}>{val.testmonial_designation}</EllipsisText> </li>
                </ul>
              </div>

              <svg xmlns="http://www.w3.org/2000/svg" width="103" height="85" viewBox="0 0 103 85" fill="none">
<path d="M103 84.8721L56.032 84.8721L56.032 37.7525L74.8664 7.87535e-05L98.2796 7.67066e-05L79.5396 37.7525L103 37.7525L103 84.8721Z" fill="#FF7350" fill-opacity="0.1"/>
<path d="M46.9678 84.8721L-0.000160217 84.8721L-0.000164337 37.7525L18.8342 7.87535e-05L42.2474 7.67066e-05L23.5074 37.7525L46.9678 37.7525L46.9678 84.8721Z" fill="#FF7350" fill-opacity="0.1"/>
</svg>
            </Card.Header> : null}
            {val.content_type==="content" ?   <Card.Body>
            <EllipsisText lineClamp={mobile ? "6" : "8"}>{val.testmonial_content}</EllipsisText>
            </Card.Body>: null}

            {val.content_type==="video" ?   <Card.Body>
            <iframe width="100%" height="100%" src={val.video_link} className="rounded-3" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> 
            </Card.Body>: null}
           </Card>
          </SwiperSlide>
              </>)
            })
          }
      </Swiper>
              </Fade>
            </Col>
          </Row>
        </Container>
       
      </div> : null}


    </>
  )
}

export const VideoPortfolio = () => {
  let  {mediaCollection , mobile} = useContext(GlobalData);
  return (
    <>
    <div className="customSliderContainer">
        <Swiper
        navigation={true}
        pagination={false}
        mousewheel={true}
        keyboard={true}
        spaceBetween={10}
        modules={[Pagination, Mousewheel, Keyboard]}
        className="mySwiper" >

        {mediaCollection?.sort((a:any, b:any) => a.orderAt - b.orderAt)?.map((service: any, i: number) => {
          return (
            <SwiperSlide  className="px-3" key={service.id} >
              <Fade right delay={i * 50}>
                <div className="rouded-4 overflow-hidden" style={{width : '100%' , height: mobile ? 250 :  350 , borderRadius : '16px'}}>
                <iframe width="100%" height="100%" src={service.video_url} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> 
                </div>
              </Fade>
            </SwiperSlide>
          );
        })}
      </Swiper>
      </div>
    </>
  );
};