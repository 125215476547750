import React, { useContext } from 'react'
import {Helmet} from "react-helmet";
import { GlobalData } from '../contextapi/ContextProvider';
const HelmetTag = () => {
    let {
        settingsCollection
        } = useContext(GlobalData);
  return (
    <Helmet>
    <meta charSet="utf-8" />
    <title>{settingsCollection.site_name || 'Dynamic Technosoft'}</title>
    <link rel="icon" type="image/x-icon" href={settingsCollection.favicon} />
    <meta name="google-site-verification" content={settingsCollection.google_integration} />
    <meta name="description" content={settingsCollection.site_description} />
    <meta name="keywords" content={settingsCollection.site_keywords} />
</Helmet>
  )
}

export default HelmetTag