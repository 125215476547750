import { Col, Container, Row } from "react-bootstrap";
import { strings } from "../../Api/Localization";
import academic from "../../Assets/products/academic.png";
import exam from "../../Assets/products/exam.png";
import fee from "../../Assets/products/fee.png";
import library from "../../Assets/products/library.png";
import blueWave from "../../Assets/blueVector.png";
import yellowWave from "../../Assets/yellowVector.png";
import { Fade } from "react-reveal";
import {
  ArrowBox,
  ArrowBoxText,
  ArrowWithTipBox,
  BR,
  FilledLine,
  ParagraphText,
  Title,
  Title40,
} from "../../Styles/StyleComponent";
import { CircleIcon, LeftArrow } from "./VectorIcon";
import { ProductEvenItems, ProductOddItems } from "./ProductHelper";
import BackgroundContainer from "../../utils/BackgroundContainer";
import erpImage from '../../Assets/new/erpMockup.png'
import module1 from '../../Assets/new/module1.png'
import module2 from '../../Assets/new/module2.png'
import module3 from '../../Assets/new/module3.png'
import module4 from '../../Assets/new/module4.png'
import module5 from '../../Assets/new/module5.png'
import module6 from '../../Assets/new/module6.png'
import module7 from '../../Assets/new/module7.png'
import module8 from '../../Assets/new/module8.png'
import module9 from '../../Assets/new/module9.png'
import module10 from '../../Assets/new/module10.png'
import module11 from '../../Assets/new/module11.png'
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";




const academicProducts = {
  academicManagement: {
    title: "academic_management",
    description: "academic_management_decription",
    features: [
      "class_setup",
      "add_student",
      "student_remarks",
      "subject_setup",
      "class_time_table",
      "lesson_plan",
      "report"
    ],
    image: academic,
  },
  examManagement: {
    title: "exam_management",
    description: "exam_management_description",
    features: [
      "exam_schedule",
      "generate_admit_card",
      "mark_entry_and_result_summary",
      "mark_sheet_and_reports",
      "instant_alert_and_notification_to_parents",
    ],
    image: exam,
  },
  feeManagement: {
    title: "fee_management",
    description: "fee_management_description",
    features: [
      "generate_fee_item",
      "manage_fee_item",
      "online_fee_payment",
      "fee_receipt",
      "bill_generate_and_bill_print",
      "transfer_fee_receipt_bank_acc",
      "income_statement",
      "reminder_slip",
      "mobile_notification_to_parents",
    ],
    image: fee,
  },
  libraryManagement: {
    title: "library_management",
    description: "library_management_description",
    features: [
  "master_library",
  "book_entry",
  "print_barcode",
  "library_membership",
  "library_card",
  "book_issue",
  "book_receive"
    ],
    image: library,
  },
  transportationManagement: {
    title: "library_management",
    description: "library_management_description",
    features: [
      "route_schedule",
      "gps_tracking_of_vehicle",
      "manage_transport_expenses",
      "vehicle_profile_manager",
      "instant_sms_alerts",
      "transfer_fee_receipt_bank_acc"
    ],
    image: library,
  },
  accountingManagement: {
    title: "library_management",
    description: "library_management_description",
    features: [
      "cash_payments_and_receipts",
      "journal_ledger",
      "trial_balance_and_sheet",
      "income_and_expenditure",
      "audit_reports"
    ],
    image: library,
  },
  smsManagement: {
    title: "library_management",
    description: "library_management_description",
    features: [
      "fee_alerts",
      "result_alerts",
      "news_information_alerts",
      "holiday_information",
      "urgent_alert_to_parents"
    ],
    image: library,
  },
  userManagement: {
    title: "library_management",
    description: "library_management_description",
    features: [
      "username_and_password_generation",
      "set_users_access_level_control",
      "grant_special_permission",
      "block_any_user"
    ],
    image: library,
  },
  hrManagement: {
    title: "library_management",
    description: "library_management_description",
    features: [
      "employee_profile_management",
      "salary_calculation_automation",
      "employee_summary_and_reports",
      "load_interest_management",
      "account_module_integration"
    ],
    image: library,
  },
  accManagement: {
    title: "library_management",
    description: "library_management_description",
    features: [
      "supplier_management",
      "purchase",
      "opening_stock",
      "product_management"
    ],
    image: library,
  },
  hostelManagement: {
    title: "library_management",
    description: "library_management_description",
    features: [
      "students_fee_collection",
      "allocate_rooms_to_students",
      "manage_room_details",
      "boarders_type",
      "boarder_student_details"
    ],
    image: library,
  },
};


const newModules = [
  {
    id: 0,
    icon : module1,
    title : 'Academic Management',
    para : (
      <p>Unlock the power of digital transformation for your school with a comprehensive platform that puts you in control from anywhere. Say goodbye to complexity and welcome a systematic approach that simplifies management tasks. <BR/>
      With our integrated solution, you can efficiently handle various aspects of school administration, academic management, and more. Experience the convenience of centralized control and easy access, ensuring that your school operates seamlessly in the digital age.<BR/>
      Embrace the future of education administration and optimize your school's efficiency with our single-platform solution.
      </p>
    ),
    products: academicProducts.academicManagement
  },

  {
    id: 1,
    icon : module2,
    title : 'Exam Management',
    para : (
      <p>During the busiest times in any school year “examination periods” we offer a solution to alleviate the workload for school staff. Our user-friendly mobile apps enable teachers to effortlessly input student marks, significantly reducing administrative burdens. Simultaneously, students and parents can access and download results with ease, making the entire process smoother and more efficient for all stakeholders.</p>
    ),
    products: academicProducts.examManagement
  },
  {
    id: 2,
    icon : module3,
    title : 'Fee Management',
    para : (
      <p>Experience the utmost in safety and security when it comes to online fee collection and payments. Our portal offers a reliable solution, integrating seamlessly with the industry's best payment gateways, including eSewa and Khalti. Rest assured that your financial transactions are in capable hands, with state-of-the-art security measures in place to protect your data and ensure a hassle-free payment experience.</p>
    ),
    products: academicProducts.feeManagement
  },
  {
    id: 3,
    icon : module4,
    title : 'Library Management',
    para : (
      <p>Take control of your library's organization and maintenance with ease. Our solution simplifies the process, enabling efficient book management, easy borrowing, and real-time tracking of book statuses. Simplify your library operations and ensure a seamless experience for both staff and patrons.</p>
    ),
    products: academicProducts.libraryManagement
  },
  {
    id: 3,
    icon : module5,
    title : 'Transportation Management',
    para : (
      <p>Simplify your school's transportation management with our comprehensive solution. We offer convenient bus schedule management and streamlined fee collection. Prioritize student and driver safety with robust GPS tracking and navigation features, ensuring peace of mind for all stakeholders. Trust us to optimize your transportation operations for efficiency and security.</p>
    ),
    products: academicProducts.transportationManagement
  },
  {
    id: 6,
    icon : module6,
    title : 'Accounting System',
    para : (
      <p>Elevate your institution's financial management to new heights with our cutting-edge solution. We enable accurate and efficient management of critical financial components, including journal entries, ledgers, trial balances, balance sheets, cash flow statements, and audit reports. Our platform accelerates financial operations, ensuring precision and efficiency, while providing the insights you need to make informed decisions. Trust us to enhance your institution's financial success.</p>
    ),
    products: academicProducts.accountingManagement
  },
  {
    id: 3,
    icon : module7,
    title : 'SMS Management',
    para : (
      <p>Prioritize parent-school communication and engagement with our powerful solution. We ensure parents stay connected and well-informed about their children's progress through instant SMS notifications. From holiday alerts to exam schedules, fee reminders, and event updates, we keep parents in the loop, fostering a strong partnership between families and educational institutions. Trust us to enhance parental involvement and communication for the benefit of students' success.</p>
    ),
    products: academicProducts.smsManagement
  },
  {
    id: 3,
    icon : module8,
    title : 'User Management',
    para : (
      <p>Prioritize security and customization with our robust system. We provide unique login credentials for each user, guaranteeing the highest level of security. Additionally, our platform grants access to specific functionalities and modules based on user roles, ensuring a tailored and secure experience for all. Trust us to safeguard your data while optimizing access for improved efficiency and control.</p>
    ),
    products: academicProducts.userManagement
  },
  {
    id: 3,
    icon : module9,
    title : 'HR & Payroll Management',
    para : (
      <p>Simplify and streamline your employee management processes with our comprehensive solution. Our platform offers a centralized hub where you can effortlessly maintain employee profiles, attendance records, salary details, deductions, leave records, and more. Experience the convenience of having all essential employee data in one place, enhancing efficiency and accuracy in workforce management. Trust us to optimize your employee administration for greater productivity and control.</p>
    ),
    products: academicProducts.hrManagement
  },
  {
    id: 3,
    icon : module10,
    title : 'Accounting Solution',
    para : (
      <p>Streamline your school's inventory management with our comprehensive solution. Gain full control and maintain meticulous records of your school's inventory. Generate insightful stock reports, manage product inspections, and keep organized records of purchase orders effortlessly. With our system, you can enhance inventory efficiency and ensure accurate stock management, empowering your institution for greater control and informed decision-making. Trust us to optimize your school's inventory management process</p>
    ),
    products: academicProducts.accManagement
  },
  {
    id: 3,
    icon : module11,
    title : 'Hostel Management',
    para : (
      <p>Take control of your school's hostel management with our comprehensive solution. Simplify the allocation of students to hostel rooms, maintain organized hostel records, and facilitate easy payment of hostel bills. Our system allows you to efficiently administrate all the necessary details of the school's hostel, ensuring a seamless experience for both staff and students. Trust us to optimize your hostel management processes for greater efficiency and organization.</p>
    ),
    products: academicProducts.hostelManagement
  },
]

export const AcademicErp = () => {

  let {
    allpagesCollection,mobile
    } = useContext(GlobalData);


  return (
    <>
         <BackgroundContainer title={allpagesCollection.product_1_title || 'Dynamic Academic ERP'} description={'You can contact us at any time for support. We value our customers more than anything & put our best to provide you with the best quality support.'} > 
 


      <Container className="my-5 ">
<Row className="py-4">

<Col xs={12} style={{marginBottom : mobile ? 20: 60}}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Simplifying the Pursuit of Academic Excellence</span></Title>
           <p>With the help of our dynamic academic ERP, your educational institution can reach its maximum potential.</p>
      </Fade>
      </Col>
      </Row>
    </Col>

          <Col lg={8} className="bg-grey order-2 order-sm-1">
          <Fade up delay={200}>
           <Title40 > <span style={{color:"#233d63"}}>{allpagesCollection.product_1_title}</span></Title40>
      </Fade>
          <Fade up delay={200}>   <ParagraphText color="#000">

          <div className="blogParagraph" dangerouslySetInnerHTML={{ __html: allpagesCollection.product_1_description || '' }} ></div>

          </ParagraphText>
            </Fade>
          </Col>
          <Col
            lg={4}
            className=" d-flex justify-content-lg-end justify-content-center  order-1 order-sm-2"
          >
            <img src={allpagesCollection.product_1_image || erpImage} alt="" className="img-fluid animatedUp" style={{zIndex: '2' , objectFit : 'contain'}} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>     <h3 className="mt-2" style={{color : '#0082C6'}}>Modules</h3></Col>



          {newModules?.map((val,index)=>{
            return(<>

<Col xl={6} className="my-lg-3 my-3" key={index}>
              <div className="cardsServices-new">
                <h4 className="mt-2 border-bottom w-100 p-3"> <img src={val.icon} alt="" className="me-2" /> {val.title}</h4>
                <p className="px-3">{val.para}</p>
               {index%2 === 0 ? <ProductOddItems products={val.products} /> : <ProductEvenItems products={val.products} />}
              </div>
            </Col>


            </>)
          }) }


        </Row>
          

      </Container>
      
      {/* <ProductEvenItems products={academicProducts.examManagement} />
      <ProductOddItems products={academicProducts.feeManagement} />
      <ProductEvenItems products={academicProducts.libraryManagement} />

      <div className="position-relative">
        <YellowWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={4}>
              <div className="  d-flex " style={{ height: "300px" }}>
                <img
                  src={transportation}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
            <Col lg={8}>
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("transportation_management")}
                </ContainerTitle>
                <Fade up delay={100}>
                  <ParagraphText color="#000">
                    {strings.getString("transportation_management_decription")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap " style={{ marginLeft: "25px" }}>
                <ArrowBoxFirst
                  firstText="route_schedule"
                  secondText="gps_tracking_of_vehicle"
                />
                <ArrowBoxLeft
                  firstText="manage_transport_expenses"
                  secondText="vehicle_profile_manager"
                />
                <ArrowBoxLeft
                  firstText="instant_sms_alerts"
                  secondText="transfer_fee_receipt_bank_acc"
                />
                <ArrowBoxLeft firstText="student_maping" />
              </div>
            </Col>
          </Row>
        </Container>
      </div> 
      <div className="position-relative">
        <BlueWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={8} className="order-lg-1 order-2">
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("accounting_system")}
                </ContainerTitle>
                <Fade up delay={100}>
                  <ParagraphText color="#000">
                    {strings.getString("accounting_system_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap position-relative justify-content-md-start justify-content-center">
                <ArrowTipBox
                  color="#F9A51B"
                  text="cash_payments_and_receipts"
                />
                <ArrowTipBox color="#007EC5" text="journal_ledger" />
                <ArrowTipBox color="#F9A51B" text="trial_balance_and_sheet" />
                <ArrowTipBox color="#007EC5" text="income_and_expenditure" />
                <ArrowTipBox color="#F9A51B" text="audit_reports" />
              </div>
            </Col>
            <Col lg={4} className="order-lg-2 order-1">
              <div
                className=" d-flex  justify-content-end"
                style={{ height: "300px" }}
              >
                <img
                  src={accounting}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="position-relative">
        <YellowWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={4}>
              <div className="  d-flex " style={{ height: "300px" }}>
                <img
                  src={sms}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
            <Col lg={8}>
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("sms_management")}
                </ContainerTitle>
                <Fade up delay={100}>
                  <ParagraphText color="#000">
                    {strings.getString("sms_management_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap " style={{ marginLeft: "25px" }}>
                <ArrowBoxFirst
                  firstText="fee_alerts"
                  secondText="result_alerts"
                />
                <ArrowBoxLeft
                  firstText="news_information_alerts"
                  secondText="holiday_information"
                />
                <ArrowBoxLeft firstText="urgent_alert_to_parents" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="position-relative">
        <BlueWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={8} className="order-lg-1 order-2">
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("user_management")}
                </ContainerTitle>
                <Fade up delay={100}>
                  <ParagraphText color="#000">
                    {strings.getString("user_management_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap position-relative justify-content-md-start justify-content-center">
                <ArrowTipBox
                  color="#F9A51B"
                  text="username_and_password_generation"
                />
                <ArrowTipBox
                  color="#007EC5"
                  text="set_users_access_level_control"
                />
                <ArrowTipBox color="#F9A51B" text="grant_special_permission" />
                <ArrowTipBox
                  color="#007EC5"
                  text="edit_user_details_and_reset_password"
                />
                <ArrowTipBox color="#F9A51B" text="block_any_user" />
              </div>
            </Col>
            <Col lg={4} className="order-lg-2 order-1">
              <div
                className=" d-flex  justify-content-end"
                style={{ height: "300px" }}
              >
                <img
                  src={user}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="position-relative">
        <YellowWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={4}>
              <div
                className=" position-relative d-flex "
                style={{ height: "300px" }}
              >
                <img
                  src={hr}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
            <Col lg={8}>
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("hr_and_payroll_management")}
                </ContainerTitle>
                <Fade up delay={100}>
                  <ParagraphText color="#000">
                    {strings.getString("hr_and_payroll_management_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap " style={{ marginLeft: "25px" }}>
                <ArrowBoxFirst
                  firstText="employee_profile_management"
                  secondText="salary_calculation_automation"
                />
                <ArrowBoxLeft
                  firstText="employee_summary_and_reports"
                  secondText="load_interest_management"
                />
                <ArrowBoxLeft firstText="account_module_integration" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="position-relative">
        <BlueWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={8} className="order-lg-1 order-2">
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("accounting_solution")}
                </ContainerTitle>
                <Fade up delay={100}>
                  <ParagraphText color="#000">
                    {strings.getString("accounting_solution_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap position-relative justify-content-md-start justify-content-center">
                <ArrowTipBox color="#F9A51B" text="product_management" />
                <ArrowTipBox color="#007EC5" text="opening_stock" />
                <ArrowTipBox color="#F9A51B" text="purchase" />
                <ArrowTipBox color="#007EC5" text="supplier_management" />
              </div>
            </Col>
            <Col lg={4} className="order-lg-2 order-1">
              <div
                className=" d-flex  justify-content-end"
                style={{ height: "300px" }}
              >
                <img
                  src={accounting}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="position-relative">
        <YellowWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={4}>
              <div
                className=" position-relative d-flex "
                style={{ height: "300px" }}
              >
                <img
                  src={hotel}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
            <Col lg={8}>
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("hostel_management")}
                </ContainerTitle>
                <Fade up delay={100}>
                  <ParagraphText color="#000">
                    {strings.getString("hostel_management_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap " style={{ marginLeft: "25px" }}>
                <ArrowBoxFirst
                  firstText="boarder_student_details"
                  secondText="boarders_type"
                />
                <ArrowBoxLeft
                  firstText="manage_room_details"
                  secondText="allocate_rooms_to_students"
                />
                <ArrowBoxLeft firstText="students_fee_collection" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
*/}
      </BackgroundContainer>
    </>
  );
};

export const ArrowBoxFirst = ({ firstText, secondText }: any) => (
  <div style={{ marginLeft: "-15px" }}>
    <Fade down delay={100}>
      <ArrowBox>
        <ArrowBoxText>{strings.getString(`${firstText}`)}</ArrowBoxText>
      </ArrowBox>
    </Fade>
    <Fade up delay={100}>
      <ArrowBox
        border="3px solid #007EC5"
        background="#F9A51B"
        transform="rotate(45deg)"
        margin=" -15px 0px 0px 75px"
      >
        <ArrowBoxText transform="rotate(-45deg)">
          {strings.getString(`${secondText}`)}
        </ArrowBoxText>
      </ArrowBox>
    </Fade>
  </div>
);

export const ArrowBoxLeft = ({ firstText, secondText }: any) => (
  <div style={{ marginLeft: "-15px" }}>
    <Fade down delay={100}>
      <ArrowBox>
        <ArrowBoxText>{strings.getString(`${firstText}`)}</ArrowBoxText>
      </ArrowBox>
    </Fade>
    <Fade up delay={100}>
      {secondText && (
        <ArrowBox
          border="3px solid #007EC5"
          background="#F9A51B"
          transform="rotate(45deg)"
          margin=" -15px 0px 0px 75px"
        >
          <ArrowBoxText transform="rotate(-45deg)">
            {strings.getString(`${secondText}`)}
          </ArrowBoxText>
        </ArrowBox>
      )}
    </Fade>
  </div>
);

export const ArrowTipBox = ({
  color,
  text,
}: {
  color: string;
  text: string;
}) => (
  <>
    <div
      style={{
        height: "130px",
        position: "relative",
        marginTop: "12px",
      }}
    >
      <Fade up delay={100}>
        <ArrowWithTipBox background={color}>
          <LeftArrow color={color !== "#F9A51B" ? "#0057A5" : "#FF9700"} />
          <ArrowBoxText transform="rotate(0deg)">
            {strings.getString(`${text}`)}
          </ArrowBoxText>
        </ArrowWithTipBox>
      </Fade>

      <div>
        <CircleIcon
          color={color === "#F9A51B" ? "#0057A5" : "#FF9700"}
        ></CircleIcon>
        <FilledLine />
      </div>
    </div>
  </>
);

export const YellowWave = () => (
  <img
    src={yellowWave}
    alt=""
    height="300px"
    style={{
      position: "absolute",
      left: "0px",
      top: "0px",
      zIndex: "-1",
    }}
  />
);

export const BlueWave = () => (
  <img
    src={blueWave}
    alt=""
    height="300px"
    style={{
      position: "absolute",
      right: "0px",
      top: "15px",
      zIndex: "-1",
    }}
  />
);
