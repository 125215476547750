import { Col, Container, Row } from "react-bootstrap";
import otherpagebg from "../Assets/new/otherpagebg.png";
import { Animation } from "./Animation";
import { Fade } from "react-reveal";
import { SubTitle, Title } from "../Styles/StyleComponent";
import { strings } from "../Api/Localization";
import { useContext, useEffect, useState } from "react";
import { GlobalData } from "../contextapi/ContextProvider";


const BackgroundContainer = ({children, title, description}) => {

  let {mobilelg} = useContext(GlobalData);

  return (<>
      {mobilelg ? 
      
      <>  {children} </>
      :
       <div className="mainContentBox position-relative w-100" 
       style={{backgroundImage : `url(${otherpagebg})` , backgroundSize : '100% 70vh' , backgroundRepeat : 'no-repeat' , marginTop : -80 }}
      >
      
        <Animation />
 
      {children}
  </div>}
  
  </>)
}

export default BackgroundContainer