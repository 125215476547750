import { CoverImage } from "../helper/CoverImage";
import teamImage from "../../Assets/coverImage/team.png";
import { Col, Container, Row } from "react-bootstrap";
import { strings } from "../../Api/Localization";
import brainlight from "../../Assets/group/brainlight.png";
import cems from "../../Assets/group/cems.png";
import complexit from "../../Assets/group/complexit.png";
import gyanmandir from "../../Assets/group/gyanmandir.png";
import pivotal from "../../Assets/group/pivotal.png";
import sansika from "../../Assets/group/sansika.png";
import dynamicskills from "../../Assets/group/dynamicskills.png";
import dynamicmedia from "../../Assets/group/dynamicmedia.png";
import { Fade } from "react-reveal";
import {
  BR,
  FilterImage,
  ImageContainer,
  ParagraphText,
  SubTitle,
  Title,
  Title40,
} from "../../Styles/StyleComponent";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";

export const DynamicGroup = () => {

  let {mobile} = useContext(GlobalData);
  return (
    <>
      {/* <CoverImage image={teamImage} path="dynamic_group" type="about_us" /> */}

      <BackgroundContainer title={'Dynamic group'} description={''} > 
      <Container className="mt-5 mb-5">
      

        <Row>

        <Col xs={12} style={{marginBottom : mobile ? 20: 60}}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Discover Excellence with Our Dynamic Group</span></Title>
           <p> Our group of enthusiastic professionals is bound together by a common dedication to excellence, innovation, and advancement. </p>
      </Fade>
      </Col>
      </Row>
    </Col>


        <Col xs={12}>
        <Fade up delay={200}>  <Title40 > <span style={{color:"#233d63"}}>{'Dynamic Group: Empowering Societies Through IT and Modern Skills'}</span></Title40>
        </Fade>
      </Col>
        </Row>

        <div className="mt-4">
          <Fade up delay={100}>
            <ParagraphText color="#000">
            Dynamic Technosoft Pvt. Ltd stands as a pivotal member of the Dynamic Group, a consortium of seven companies dedicated to making a positive impact on society. Our collective focus on IT and contemporary skills aims to drive societal progress and empowerment.

<BR/> Within the Dynamic Group, several companies work tirelessly to enhance education systems and impart cutting-edge tech knowledge to the younger generation. Dynamic Skills Pvt. Ltd, Dynamic Media Networks & Communication Pvt. Ltd., Gyan Mandir Academy Pvt. Ltd., and Dynamic Enterprises are committed to equipping the youth with essential 21st-century skills.

<BR/> On the other hand, companies such as Dynamic Technosoft Pvt. Ltd., Brainlight Technologies Pvt. Ltd., Pivotal Technologies, and Codeplex IT Enterprises specialize in offering technology-driven solutions to complex challenges. Our software and products have proven instrumental in enhancing the services and profitability of numerous enterprises.

<BR/> With a dedicated workforce of over 150 employees who share common values and principles, Dynamic Group stands as a formidable institution in our nation. We are steadfastly committed to serving our clients and stakeholders with the utmost excellence."


            </ParagraphText>
          </Fade>
        </div>

        <Row className="g-4 mt-4 justify-content-center justify-content-md-start">
          <Group image={cems} />
          <Group image={brainlight} />
          <Group image={dynamicmedia} />
          <Group image={dynamicskills} />
          <Group image={pivotal} />
          <Group image={sansika} />
          <Group image={complexit} />
          <Group image={gyanmandir} />
        </Row>
      </Container>
      </BackgroundContainer>
    </>
  );
};

const Group = ({ image }: { image: any }) => (
  <Col
    style={{ maxWidth: "187px", minWidth: "187px" }}
    className="d-flex justify-content-center align-items-center"
  >
    <Fade left>
      <ImageContainer>
        <FilterImage>
          <img
            src={image}
            alt={"Dynamic Group"}
            width="100%"
            height="100%"
            style={{ objectFit: "contain" }}
          />
        </FilterImage>
      </ImageContainer>
    </Fade>
  </Col>
);
