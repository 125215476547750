import { Col, Container, Row } from "react-bootstrap";
import {
  Title,

} from "../../Styles/StyleComponent";

import { useParams } from "react-router-dom";

import { Fade } from "react-reveal";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { useContext, useEffect, useState } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";
import { dateConvert } from "../../utils/dateConvert";
import { HomeBlogsSliders } from "../Sliders/ServiceSlider";
import { Helmet } from "react-helmet";


// Define the BlogData interface for blog posts
interface BlogData {
  id: number;
  title: string;
  short_description: string;
  long_description: string;
  image: string;
  createdAt :  string;
  keywords : string;
  seo_title : string;
  seo_description : string;
  seo_keywords : string;

  // Add any other properties from your actual data
}


export const BlogDetail = () => {
  const { blogId } = useParams();
  let {
    blogsCollection
    } = useContext(GlobalData);

    let params = useParams();

    
  // Define the state for blogData
  const [blogData, setBlogData] = useState<BlogData | null>(null);

    useEffect(()=>{
      let filterData = blogsCollection?.filter((item:any) => `${item.id}` == `${blogId}`);
        setBlogData(()=> filterData[0])

        console.log(filterData , 'filterData')
    }, [params,blogsCollection]);

  return (
    <>
    <BackgroundContainer description={''} title={''} key={''} >

    <Helmet>
        <title>{blogData?.seo_title}</title>
        <meta name="keywords" content={blogData?.seo_keywords} />
        <meta
          name="description"
          content={blogData?.seo_description}
        />
      </Helmet>
      <Container className="blog-detail-page py-3">
        <Row>
          <Col xs={12}>
          <div className="featureImg img-box">
          <img src={blogData?.image} alt="" />
          {blogData?.keywords && <div className="keywords">{blogData?.keywords}</div>}
          <div className="title-box">
            <p>{blogData?.title}</p>
          </div>
        </div>
          </Col>

          <Col xs={12} className="my-4">
          <div className="para-box">

          <div
  className="blogParagraph"
  dangerouslySetInnerHTML={{ __html: blogData?.short_description || '' }}
></div>

          <div className="blogParagraph" dangerouslySetInnerHTML={{ __html: blogData?.long_description || '' }} ></div>
        </div>
          </Col>



          <Col xs={12}>
          <Fade up delay={100}>
            <Title>
              <span style={{ color: "#007EC5" }}>Related Post</span>
            </Title>
          </Fade>
          </Col>
        </Row>



        
      </Container>


      <div className="serviceSlider" >
      <Container fluid className="px-lg-5 px-md-4 px-sm-3 px-2 ">
          
       
        </Container>
        <HomeBlogsSliders />
      </div>
    </BackgroundContainer>
    </>
  );
};




// <Container fluid>
// <Container className="py-md-0 py-4 mb-4">
//   {blogData && (
//     <Row className="align-items-center justify-content-center">
//       <Col sm={6}>
//         <div>
//           <Fade up delay={0}>
//             {/* <SubTitle>{blogdetail.category}</SubTitle> */}
//           </Fade>
//           <Fade up delay={100}>
//             <Title> {blogData?.title}</Title>
//           </Fade>
//         </div>
//       </Col>
//       <Col sm={6}>
//         <img
//           src={blogData?.image}
//           alt={blogData?.title}
//           style={{ objectFit: "contain" }}
//           width="100%"
//           height="300px"
//         ></img>
//       </Col>
//       <Col sm={12} className="my-4">
//         <div className="d-flex justify-content-start align-items-center">
//           <div className="d-flex px-3 py-1">
//             <UserIcon />
//             <PrimaryText fontSize="18px" color="#0d97d6">
//               {blogdetail?.author?.name}
//             </PrimaryText>
//           </div>
//           <div className="d-flex px-3 py-1">
//             <Calendar />
//             <PrimaryText fontSize="18px" color="#0d97d6">
//               {dateConvert(blogData.createdAt)}
//             </PrimaryText>
//           </div>
//         </div>
//       </Col>
//       <Col
//         sm={12}
//         className="d-flex justify-content-center align-items-center"
//       >
//         <SecondaryText fontSize="18px" fontWeight="500">
//           Share :
//         </SecondaryText>
//         <div className="d-flex justify-content-around align-items-center w-25">
//           <Facebook color="#000" />
//           <Instagram color="#000" />
//           <Twitter color="#000" />
//           <Linkedin color="#000" />
//         </div>
//       </Col>
//     </Row>
//   )}

//   <Row className="mt-4 justify-content-between">
//     <Col lg={8}>
//     <div
//   className="blogParagraph"
//   dangerouslySetInnerHTML={{ __html: blogData?.short_description || '' }}
// ></div>

// <div className="blogParagraph" dangerouslySetInnerHTML={{ __html: blogData?.long_description || '' }} ></div>
//     </Col>
//     <Col lg={4} className=" ps-lg-5" style={{ minWidth: "320px" }}>
//       <TitleText className="m-0">Latest Blogs</TitleText>

//       <div>
//         {blogsCollection.map((blog:any) => {
//           if (blog.id !== params?.blogId) {
//             return (
//               <div className="d-flex my-3" key={blog.id}>
//                 <img
//                   src={blog.image}
//                   alt=""
//                   height="75px"
//                   width="75px"
//                   style={{ objectFit: "cover" }}
//                 />
//                 <div className="ms-3 d-flex flex-column justify-content-start">
//                   <EllipsisText
//                     lineClamp="3"
//                     fontWeight="450"
//                     fontSize="16px"
//                   >
//                     {blog.title}
//                   </EllipsisText>
                 
//                   <Button
//                     className="my-1 text-start"
//                     background="transparent"
//                     padding="0px"
//                     color="#000"
//                     onClick={() => {
//                       navigate(
//                         "/blogs/" +
//                           blog.id +
//                           "/" +
//                           blog.title.replace(/ /g, "-")
//                       );
//                       window.scrollTo({
//                         top: 0,
//                         left: 0,
//                         behavior: "smooth",
//                       });
//                     }}
//                     border="none"
//                   >
//                     Read More
//                     <svg
//                       width="12"
//                       height="9"
//                       viewBox="0 0 12 9"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M11.1113 4.79276C11.3066 4.5975 11.3066 4.28092 11.1113 4.08566L7.92933 0.903675C7.73407 0.708413 7.41749 0.708413 7.22223 0.903675C7.02696 1.09894 7.02696 1.41552 7.22223 1.61078L10.0507 4.43921L7.22223 7.26764C7.02696 7.4629 7.02696 7.77948 7.22223 7.97474C7.41749 8.17001 7.73407 8.17001 7.92933 7.97474L11.1113 4.79276ZM0.656006 4.93921H10.7578V3.93921H0.656006V4.93921Z"
//                         fill="#0D97D6"
//                       />
//                     </svg>
//                   </Button>
//                 </div>
//               </div>
//             );
//           }
//           return null;
//         })}
//       </div>
//     </Col>
//   </Row>
// </Container>
// </Container>