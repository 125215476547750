import { Col, Container, Row } from "react-bootstrap";
import teamImage from "../../Assets/coverImage/team.png";
import { CoverImage } from "../helper/CoverImage";
import ceoImage from "../../Assets/Ceo.png";
import ctoImage from "../../Assets/cto.png";
import cooImage from "../../Assets/coo.png";
import { useState } from "react";
import { Fade } from "react-reveal";

import {
  Button,
  EllipsisDiv,
  EllipsisText,
  FadeAway,
  SubTitle,
  SubTitleText,
  TeamCard,
  Title,
} from "../../Styles/StyleComponent";
import BackgroundContainer from "../../utils/BackgroundContainer";

export const Team = () => {
  const [show, setShow] = useState<any | string>("Chief Executive Officer");
  const [viewMore, setViewMore] = useState<boolean>(false);
  const teamMembers = [
    {
      id: 1,
      position: "Chief Executive Officer",
      name: "Deepak Sah",
      image: ceoImage,
      messageTitle:
        "I am completely delighted to introduce you to Dynamic Technosoft",
      message: (
        <>
          <p
            style={{
              fontSize: "16px",
              marginBottom: "4px",
              lineHeight: "22px",
            }}
          >
            I am completely delighted to introduce you to Dynamic Technosoft.
            From its establishment in 2010, Dynamic Technosoft has emerged as a
            quality service provider of web-related services, with a
            result-driven approach and a strong client portfolio.
          </p>
          <p
            style={{
              fontSize: "16px",
              marginBottom: "4px",
              lineHeight: "22px",
            }}
          >
            Our aim to provide our clients with the best service possible has
            allowed us to develop into a company that aspires to become best
            every day. With our ability to use dynamic technologies, we have
            always been able to keep the needs of our clients at the forefront
            of our company’s focus. Whether you are looking for software to
            manage your institution or looking for mobile application to boost
            your business, we assure you that our highly skilled team will
            provide you with the best services that are cost-efficient. The wide
            range of solutions that we provide irrespective of the size of your
            requirements is sure to provide you a very rewarding experience.
          </p>

          <p
            style={{
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            We look forward to providing you with the dynamic solutions that
            will address even the complex problems faced by your institution..
          </p>
        </>
      ),
    },
    {
      id: 2,
      position: "Chief Technical Officer",
      image: ctoImage,
      name: "Sachin Sah",
      messageTitle: "Success for me is not a destination but a journey",
      message: (
        <>
          <p
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              marginBottom: "4px",
            }}
          >
            Success for me is not a destination but a journey. And to take on
            that journey we established Dynamic Technosoft, a company that aims
            to digitize Nepal. After a lot of challenges and struggles, Dynamic
            Technosoft became a prominent name in the IT service provider
            segment. With our other expansions like a branch office in Japan, we
            are set to take our company to the next level. More than being a
            global player, we always perceived our company as a unit that could
            make a difference with its quality and competitively priced
            products. With a highly skilled team and expertise in digital
            service providing, our clients are very satisfied with our services.
          </p>
          <p
            style={{
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            Dynamic Technosoft is more than just a corporate entity, for me,
            this is a platform that we are using to digitize Nepal and provide
            solutions to a lot of complex challenges. With our core values and
            expertise, we are a partner for your success. Overcoming challenge
            after challenge with the support of my brilliant team, we have been
            successful in bringing Dynamic Technosoft to a milestone of national
            recognition. Our quality services have helped us acquire more than
            500 clients including some of the corporate giants like Dabur Nepal,
            Dugar Group, Nissan Motor, Hulas Steel, Himgiri Group and many more.
          </p>
        </>
      ),
    },
    {
      id: 3,
      position: "Chief Operating Officer",
      name: "Arjun Sah",

      image: cooImage,
      messageTitle:
        "For many years we have witnessed consisten growth and achieved long-term success",
      message: (
        <>
          <p
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              marginBottom: "4px",
            }}
          >
            For the last decade, Dynamic Technosoft has provided the Nepali
            market with unique IT services that have allowed various businesses
            to maximize their profit. For many years we have witnessed
            consistent growth and achieved long-term success.
          </p>
          <p
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              marginBottom: "4px",
            }}
          >
            Our services like Dynamic Academic ERP and Hospital ERP are making
            it easier for institutions like Schools and Hospitals to provide the
            best of their services. Whereas our other products like Accounting
            Solution and Chamber Software helps various Chamber of Commerce and
            other big corporations to maximize their benefits and promote
            transparency. Our company always prioritizes our clients’ needs, and
            I feel proud to say that we’ve met them.
          </p>
          <p
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              marginBottom: "4px",
            }}
          >
            Dynamic Technosoft is one of seven institutions run by Dynamic
            Group. And we have been through all of them trying to promote
            digital revolution and modern-day required skills. Since our
            establishment, we have always tried to become best and we are still
            on that journey. I look forward to providing you with unique
            solutions for your institution's challenges.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <BackgroundContainer title={'About Us'} description={'When your mission is to be better, faster and smarter, you need the best people driving your vision forward.'} > 


      <Container className="mt-5 mb-5 text-center ">
        {/* <div>
          <Fade up delay={0}>
            <SubTitle>About Us</SubTitle>
          </Fade>
        </div> */}
        <Row className="align-items-center">

          <Col xs={12}>
            <Row>
            <Col md={5} className="position-relative">
            <div className="text-start">
              <Fade up delay={100}>
              <Title > <span style={{color:"#127DC2"}}>{'Our Best Expertise'}</span></Title>
              </Fade>
              <Fade up delay={200}>
                <EllipsisText className=" mb-5">
                  When your mission is to be better, faster and smarter, you
                  need the best people driving your vision forward.
                </EllipsisText>
              </Fade>
            </div>
          </Col>
            </Row>
          </Col>
       
        </Row>

        <Row className="pt-3">
        <Col md={5} className="position-relative">
    

    {teamMembers.map((member) => (
      <TeamCard
        onClick={() => {
          setShow(member.position);
          setViewMore(false);
        }}
        boxShadow={
          show === member.position
            ? " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            : ""
        }
        key={member.id}
      >
        <img
          src={member.image}
          alt=""
          style={{
            width: "65px",
            height: "65px",
            borderRadius: "65px",
            marginRight: "12px",
            background: "#E9F8FF",
            border:
              show === member.position ? "2px solid #FAA831" : "none",
          }}
        ></img>
        <div>
          <SubTitleText color="#000" className="text-start ">
            {member.name}
          </SubTitleText>
          <EllipsisText>{member.position}</EllipsisText>
        </div>
      </TeamCard>
    ))}
  </Col>

  <Col md={6}>
    <Row>
      <Col sm={12} className="position-relative">
        <img
          src={
            teamMembers.find(({ position }) => position === show)?.image
          }
          alt=""
          width="300px"
          height="300px"
        />
        <FadeAway />
      </Col>
      <Col
        sm={12}
        style={{
          marginTop: "-20px",
          background: "transparent",
          opacity: "0.9",
        }}
      >
        <div style={{}}>
          <SubTitleText color="#000" className="text-center ">
            {
              teamMembers.find(({ position }) => position === show)
                ?.messageTitle
            }
          </SubTitleText>
          <EllipsisDiv
            lineClamp={viewMore ? "0" : "3"}
            className="text-start "
            display={viewMore ? "-webkit-box" : "none"}
          >
            {
              teamMembers.find(({ position }) => position === show)
                ?.message
            }
          </EllipsisDiv>
          {!viewMore && (
            <Button
              className="mt-3"
              background="#FAA831"
              padding="4px 12px"
              onClick={() => setViewMore(true)}
            >
              <EllipsisText color="#fff">View More</EllipsisText>
            </Button>
          )}
        </div>
      </Col>
    </Row>
  </Col>
        </Row>
      </Container>
      </BackgroundContainer>

    </>
  );
};
