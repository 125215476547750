import { CoverImage } from "./helper/CoverImage";
import clientCover from "../Assets/coverImage/ourClient.png";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import { SubTitle, Title } from "../Styles/StyleComponent";
import { GlobalData } from "../contextapi/ContextProvider";
import { useContext } from "react";
import BackgroundContainer from "../utils/BackgroundContainer";
export const PrivacyPolicy = () => {
  let {
    settingsCollection
    } = useContext(GlobalData);
  return(
  <>
 <BackgroundContainer title={'Privacy Policy'} description={'Website privacy policy of Use'} > 
  <Container className="py-5">

  <Col xs={12}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Privacy Policy</span></Title>
      </Fade>
      </Col>
      </Row>
    </Col>

    <div>
      <p dangerouslySetInnerHTML={{ __html: settingsCollection?.privacy_policy || '' }}></p>
    </div>
  </Container>

  </BackgroundContainer>
</>
)
};
