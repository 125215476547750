import { Col, Container, Row } from "react-bootstrap";
import { PortFolio } from "./Portfolio";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { Fade } from "react-reveal";
import { Title } from "../../Styles/StyleComponent";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";

export const PortfolioPages = () => {
  let {mobile} = useContext(GlobalData)
  return (
    <>
 <BackgroundContainer title={'Portfolio'} description={''} > 
      <Container className="py-5">
    <Col xs={12} style={{marginBottom : mobile ? 20: 60}}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Portfolio</span></Title>
           <p>Welcome to the digital haven of Dynamic Technosoft, where purpose and creativity come together. Check out our carefully chosen portfolio for a visual representation of the meeting point of expertise and passion.</p>
      </Fade>
      </Col>
      </Row>
    </Col>
        <PortFolio />
      </Container>
      </BackgroundContainer>
    </>
  );
};
