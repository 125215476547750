import { Button, Col, Container, Row } from "react-bootstrap";
import development from "../../Assets/coverImage/development.png";
import { SoftwareDevelopmentVector } from "../../Assets/DevelopmentVector";
import { BR, EllipsisText, ParagraphText, SubTitle, Title, TitleText } from "../../Styles/StyleComponent";
import { CoverImage } from "../helper/CoverImage";
import { blogLists } from "../Blogs/Blog";
import { BlogCards } from "../Blogs/blogCard";
import { Fade } from "react-reveal";
import { strings } from "../../Api/Localization";
import BackgroundContainer from "../../utils/BackgroundContainer";
import blog1 from "../../Assets/blogs/blog1detail.jpg";
import blog2 from "../../Assets/blogs/blog4.jpg";
import blog3 from "../../Assets/blogs/blog2.jpeg";
import blog4 from "../../Assets/blogs/blog3.jpg";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";
import { dateConvert } from "../../utils/dateConvert";
export const MediaCoverage = () => {

 
  let {

    mediaCoverageCollection
    } = useContext(GlobalData);
  return (
    <>

<BackgroundContainer title={'Dynamic Media Coverage'} description={'Our Latest Media Coverage'} > 


<Container className="my-5">

        <Row className="blogDetails g-4 py-4">
        <Col xs={12}>
        <Row>
        <Col lg={5}>
          <Fade up delay={200}>
             <Title > <span style={{color:"#127DC2"}}>Media Coverage</span></Title>
        </Fade>
        </Col>
        </Row>
      </Col>


          {mediaCoverageCollection?.sort((a:any, b:any) => a.orderAt - b.orderAt)?.map((blog: any, i: number) => {
            return (
              <>
               <Col sm={4} key={blog.id}>
                <Fade top delay={i * 50}>

                <div>
                  <div className="cardsServices" style={{borderRadius : '16px'}}>
                    <div className="mb-3  img-box" style={{ height: "260px", width: "100%"}}>
                      <img src={blog.image} alt="" style={{height: '100%',width : '100%' ,borderRadius : '16px' }} />
                    </div>

                    <div className="p-2 text-start w-100">
                      {dateConvert(blog.createdAt)}
                      <EllipsisText lineClamp="2"><h4 className="mt-2" style={{fontWeight : '800'}} >{blog.title}</h4></EllipsisText>             
                      <NavLink to={blog.link} target="_blank" onClick={()=>{window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });}} style={{color : '#0082C6'}}>Learn More <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
<path d="M20.7796 6.53033C21.0725 6.23744 21.0725 5.76256 20.7796 5.46967L16.0066 0.696699C15.7137 0.403806 15.2388 0.403806 14.9459 0.696699C14.653 0.989592 14.653 1.46447 14.9459 1.75736L19.1886 6L14.9459 10.2426C14.653 10.5355 14.653 11.0104 14.9459 11.3033C15.2388 11.5962 15.7137 11.5962 16.0066 11.3033L20.7796 6.53033ZM0.00354004 6.75H20.2492V5.25H0.00354004V6.75Z" fill="#0082C6"/>
</svg> </NavLink>
               
                     
                    </div>
                  </div>
                </div>


                  {/* <BlogCards blog={blog} redirect={ blog.url ? true : false } /> */}
                </Fade>
              </Col>
              
              </>


              
            );
          })}
        </Row>
      </Container>

      </BackgroundContainer>


    </>
  );
};
