import { Col, Container, Row } from "react-bootstrap";
import { CoverImage } from "../helper/CoverImage";
import { clientImages } from "./ClientImage";
import clientCover from "../../Assets/coverImage/ourClient.png";
import { strings } from "../../Api/Localization";
import { Fade } from "react-reveal";
import { useLocation } from "react-router-dom";
import {
  FilterImage,
  ImageContainer,
  SubTitle,
  Title,
} from "../../Styles/StyleComponent";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";

export const OurClient = () => {
  const location = useLocation();

  let {
    clientCollection,mobilexl
    } = useContext(GlobalData);

  return (
    <>
{location.pathname === "/our-client" ? 
   <BackgroundContainer title={'Client'} description={'Meet Our Client'} > 
          <Container className="mt-5">
        <Row className="mb-lg-5">
        <Col md={5}>
          <Fade up delay={200}>
             <Title > <span style={{color:"#127DC2"}}>Our Clients</span></Title>
             <p>Our client's enthusiasm for innovation and dedication to quality are at the core of their purpose. Their unwavering commitment to ensuring customer satisfaction makes them a shining example of excellence and dependability. From innovative solutions to individualized care, our customer consistently goes above and beyond, becoming the benchmark for excellence in their industry.</p>

        </Fade>
      </Col>

        </Row>
        <Title >Meet <span style={{color:"#127DC2"}}>Our Clients</span></Title>
        <Row className="gy-5 gx-4 mb-5  text-center mt-2 justify-content-center justify-content-md-start">
        
          {clientCollection?.sort((a:any, b:any) => a.orderAt - b.orderAt).map((i: any, ind: number) => (
              <Col
                key={ind}
                style={{ maxWidth: "187px", minWidth: "187px" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Fade up delay={ind * 100}>
                  <ImageContainer>
                    <FilterImage>
                      <a href={i.client_url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={i.client_image}
                        alt={i.client_name}
                        width="100%"
                        height="100%"
                        title={i.client_name}
                        style={{ objectFit: "contain" }}
                      />
                      </a>
                    </FilterImage>
                  </ImageContainer>
                </Fade>
              </Col>
            ))}
        </Row>
          </Container>
      </BackgroundContainer> 
      
      : 
      
      <Container className="mt-5 text-center">
        <Fade up delay={0}>
          <SubTitle>{strings.getString("client")}</SubTitle>
        </Fade>
        <Fade up delay={100}>
          <Title>
            {strings.getString("meet")}{" "}
            <span style={{ color: "#007EC5" }}>
              {strings.getString("our_client")}
            </span>
          </Title>
        </Fade>

        <Row className="gy-5 gx-4 mb-5 mt-2 justify-content-center justify-content-md-start">
        {clientCollection?.slice(0, mobilexl ? 18 : 21)?.sort((a:any, b:any) => a.orderAt - b.orderAt).map((i: any, ind: number) => (
              <Col
                key={ind}
                style={{ maxWidth: "187px", minWidth: "187px" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Fade up delay={ind * 100}>
                  <ImageContainer>
                    <FilterImage>
                      <a href={i.client_url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={i.client_image}
                        alt={i.client_name}
                        width="100%"
                        height="100%"
                        title={i.client_name}
                        style={{ objectFit: "contain" }}
                      />
                      </a>
                    </FilterImage>
                  </ImageContainer>
                </Fade>
              </Col>
            ))}
        </Row>
          </Container> }
    </>
  );
};
