import { Col, Container, Row } from "react-bootstrap";
import { strings } from "../Api/Localization";
import dotImage from "../Assets/dotImage.png";
import { ProductSliders } from "./Sliders/ProductsSlider";
import { HomeBlogsSliders, ServiceSliders} from "./Sliders/ServiceSlider";
import { useContext } from "react";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import Headers from "./Sliders/HeaderSlider";

import { Counter } from "./helper/Counter";
import { OurClient } from "./Clients/OurClient";
import {
  Button,
  SubTitle,
  Title,
} from "../Styles/StyleComponent";

import { GlobalData } from "../contextapi/ContextProvider";
import { ClientTestimonial, VideoPortfolio } from "./Sliders/HomeSlider";




export default function HomePage() {
  const navigateTo = useNavigate();

  let {
    allpagesCollection,
    achievementCollection
    } = useContext(GlobalData);

  return (
    <>

      <Headers />
      <div className="position-relative mt-5">
        <Container className="py-5 mt-5">
          <Row className="align-items-center g-3">
            <Col lg={6} className="my-2">
              <Fade left delay={200}>
                <img src={allpagesCollection.about_image} alt="" style={{objectFit : 'contain'}}   className="img-fluid" />
              </Fade>
            </Col>
            <Col className="d-flex flex-column my-2" lg={5}>
              <Fade up delay={0}>
                <SubTitle>{strings.getString("about_dynamic")}</SubTitle>
              </Fade>
              <Fade up delay={100}>
                <Title>
                {allpagesCollection.about_title || `Dynamic Technosoft: Your{" "}
                  <span style={{ color: "#233d63" }}>
                  Premier IT Service Provider
                  </span>`}
                </Title>
              </Fade>
              <Fade up delay={200}>

            

                <p dangerouslySetInnerHTML={{ __html: allpagesCollection.about_description || '' }}></p>
                <Button
                  className="m-t10"
                  background="#0082C6"
                  padding="10px 15px"
                  style={{
                    width: "140px",
                    boxShadow: "0px 27px 40px 12px rgb(34 39 49 / 15%)",
                    borderRadius: "7px",
                  }}
                  onClick={() => {
                    navigateTo("/about-us/our-company");
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  {strings.getString("read_more")}
                </Button>
              </Fade>
            </Col>
          </Row>
        </Container>
        <img
          src={dotImage}
          alt=""
          className="d-md-block d-none"
          style={{ position: "absolute", top: "0px", zIndex: "-1" }}
        />
      </div>

      <Container className="mt-lg-5">
        <ProductSliders />
      </Container>
      <div
        className=" mt-4 serviceSlider"
        style={{ marginBottom: "150px" }}
      >
      <Container fluid className="px-lg-5 px-md-4 px-sm-3 px-2 ">
 
          <Fade up delay={0}>
            <SubTitle>Service</SubTitle>
          </Fade>
          <Fade up delay={100}>
            <Title>
            Discover How Our Services {" "}
              <span style={{ color: "#007EC5" }}>Can Benefit You</span>
            </Title>
          </Fade>
       
        </Container>
        <ServiceSliders />
      </div>

      <Container
      fluid
        className="mb-5 p-sm-4 "
        style={{
          marginTop: "-100px",
          background: "#fff",
          backgroundImage : `linear-gradient(rgba(18, 125, 194, 0.7) ,rgba(18, 125, 194, 0.7)),url(${achievementCollection.achievement_bgimage})`
        }}
      >
        <Counter />
      </Container>
 

<div className="position-relative my-5" style={{background : '#F5FCFF'}}>
        <Container className="py-5 mt-5">
          <Row className="align-items-center g-3">
          <Col className="d-flex flex-column" lg={6}>
         
              <Fade up delay={100}>
                <Title>

                 {allpagesCollection.academic_erp_title ||
            <span>
            <span style={{ color: "#007EC5" }}>
            Enhance Your Understanding with Our Product Tutorial Center
            </span></span> }
                </Title>
              </Fade>
              <Fade up delay={200}>
<p dangerouslySetInnerHTML={{ __html: allpagesCollection.academic_erp_description || 'Become a part of the happy customer base that uses our products to achieve success. To maximize your knowledge and take advantage of everything we have to offer, explore our Product Tutorial Center. Our training programs are designed to meet the needs of all users, from novices to experts, offering insightful analysis and useful recommendations. Enhance your use of our products while you acquire and put to use skills that support your goals. With our reliable products, access an array of resources created to help you succeed on your path.' }}></p> 

                <Button
                  className="m-t10"
                  background="#0082C6"
                  padding="10px 15px"
                  style={{
                    width: "140px",
                    boxShadow: "0px 27px 40px 12px rgb(34 39 49 / 15%)",
                    borderRadius: "7px",
                  }}
                  onClick={()=>{
                    navigateTo('/resources/tutorials');
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
      
                >
                  Watch Now
                </Button>
              </Fade>
            </Col>
            <Col lg={6}>
              <Fade left delay={200}>
                  <VideoPortfolio />
              </Fade>
            </Col>
        
          </Row>
        </Container>
        <img
          src={dotImage}
          alt=""
          style={{ position: "absolute", top: "0px", zIndex: "-1" }}
        />
      </div>


      <ClientTestimonial customstyle={{background: 'linear-gradient(219deg, #1AA3EA 3.81%, #02559F 94.66%)'}} />

      <div
        className=" mt-4 serviceSlider"
      
      >
      <Container fluid className="px-lg-5 px-md-4 px-sm-3 px-2 ">
 
          <Fade up delay={0}>
            <SubTitle>Dynamic Blogs</SubTitle>
          </Fade>
          <Fade up delay={100}>
            <Title>
            Our Latest {" "}
              <span style={{ color: "#007EC5" }}>Blogs</span>
            </Title>
          </Fade>
       
        </Container>
        <HomeBlogsSliders />
      </div>

      <OurClient />
     
    </>
  );
}





// export const ClientTestimonial = ({customstyle}:any) =>{

//   let {
//     testmonialCollection
//     } = useContext(GlobalData);

//   return(
//     <>

// {testmonialCollection.length> 0  ? 
// <div className="position-relative my-5" style={customstyle}>
//         <Container className="py-5 mt-5 customSliderContainer">
//           <Row className="align-items-center g-3">
      
//             <Col className="d-flex flex-column text-white" sm={6}>

//               <Fade up delay={100}>
//                 <p>Testimonial</p>
//                 <Title>See the Testimonials of Our Happy Customers
//                 </Title>
//               </Fade>
//               <Fade up delay={200}>
//                 <p>Enter the domain of customer happiness as we reveal a tapestry of testimonies that tells a story of achievement, greatness, and trust. Hear from people who have used our products or services and can attest to their impact in their own words. These testimonials offer a glimpse into the real value we deliver to our clients, ranging from positive evaluations to motivational success tales. Discover the actual extent of our dedication to going above and beyond by traveling with us through the words of those who have chosen to share their positive experiences.</p>
            
//               </Fade>
//             </Col>


//             <Col sm={6} className="ms-auto">
//               <Fade left delay={200}>
//               <Swiper
//         navigation={true}
//         pagination={false}
//         mousewheel={true}
//         keyboard={true}
//         spaceBetween={10}
//         modules={[Navigation, Pagination, Mousewheel, Keyboard]}
//         className="mySwiper" >

//           {
//             testmonialCollection?.sort((a:any, b:any) => a.orderAt - b.orderAt)?.map((val:any,index:any)=>{
//               return(<>
//               <SwiperSlide className="px-3" key={index}>
//            <Card className="box w-100 p-3 text-black" style={{borderRadius: "20px",
// background:" #FAFAFA",
// height : 300,
// boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset"}}>
//           {val.content_type==="content" ?   <Card.Header className="border-0 bg-transparent d-flex justify-content-between">
//               <div className="profile d-flex align-items-center">
//                 <img src={val.image} alt="" className="rounded-4 me-2"  style={{height : '50px' , width : '50px',borderRadius: "10px" , border : '2px solid #FF7350'}} />
//                 <ul className="list-unstyled">
//                   <li style={{
//                     color: "#125875",
//                     fontWeight: "600"
//                   }}>{val.testmonial_name}</li>
//                   <li>{val.testmonial_designation}</li>
//                 </ul>
//               </div>

//               <svg xmlns="http://www.w3.org/2000/svg" width="103" height="85" viewBox="0 0 103 85" fill="none">
// <path d="M103 84.8721L56.032 84.8721L56.032 37.7525L74.8664 7.87535e-05L98.2796 7.67066e-05L79.5396 37.7525L103 37.7525L103 84.8721Z" fill="#FF7350" fill-opacity="0.1"/>
// <path d="M46.9678 84.8721L-0.000160217 84.8721L-0.000164337 37.7525L18.8342 7.87535e-05L42.2474 7.67066e-05L23.5074 37.7525L46.9678 37.7525L46.9678 84.8721Z" fill="#FF7350" fill-opacity="0.1"/>
// </svg>
//             </Card.Header> : null}
//             {val.content_type==="content" ?   <Card.Body>
//             <EllipsisText lineClamp="8">{val.testmonial_content}</EllipsisText>
//             </Card.Body>: null}

//             {val.content_type==="video" ?   <Card.Body>
//             <iframe width="100%" height="100%" src={val.video_link} className="rounded-3" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> 
//             </Card.Body>: null}
//            </Card>
//           </SwiperSlide>
//               </>)
//             })
//           }
//       </Swiper>
//               </Fade>
//             </Col>
//           </Row>
//         </Container>
       
//       </div> : null}


//     </>
//   )
// }

// const VideoPortfolio = () => {
//   const navigateTo = useNavigate();
//   const services = [
//     {
//       id: 1,
    
//       title: strings.getString("software_development"),
//       description: strings.getString("software_development_description"),
//       path: "services/software-development",
//       thumbnail : videoThumb
//     },
//     {
//       id: 2,
     
//       title: strings.getString("mobile_app_development"),
//       description: strings.getString("mobile_app_development_description"),
//       path: "services/mobile-app-development",
//       thumbnail : videoThumb
//     },
    
//   ];

//   let  {mediaCollection , mobile} = useContext(GlobalData);

//   return (
//     <>
//     <div className="customSliderContainer">
//         <Swiper
//         navigation={true}
//         pagination={false}
//         mousewheel={true}
//         keyboard={true}
//         spaceBetween={10}
//         modules={[Navigation, Pagination, Mousewheel, Keyboard]}
//         className="mySwiper" >

//         {mediaCollection?.sort((a:any, b:any) => a.orderAt - b.orderAt)?.map((service: any, i: number) => {
//           return (
//             <SwiperSlide  className="px-3" key={service.id} >
//               <Fade right delay={i * 50}>
//                 <div className="rouded-4 overflow-hidden" style={{width : '100%' , height: mobile ? 250 :  350 , borderRadius : '16px'}}>
//                 <iframe width="100%" height="100%" src={service.video_url} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> 
//                 </div>
//               </Fade>
//             </SwiperSlide>
//           );
//         })}
//       </Swiper>
//       </div>
//     </>
//   );
// };




