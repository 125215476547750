import {Card, Col, Container, Row } from "react-bootstrap";

import {EllipsisText, Title, Title40} from "../../Styles/StyleComponent";

import BackgroundContainer from "../../utils/BackgroundContainer";
import { Fade } from "react-reveal";

import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";


import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { Mousewheel, Keyboard } from "swiper";
export const Testimonial = () => {

  let {
    testmonialCollection
    } = useContext(GlobalData);


  return (
    <>
<BackgroundContainer title={'Dynamic Media Coverage'} description={'Our Latest Media Coverage'} > 
<Container className="my-5">

        <Row className="blogDetails g-4 py-4">

        <Col xs={12}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Testimonial</span></Title>
           <p>Dynamic Technosoft is a recognized leader in the field of IT service
providers providing comprehensive software development and web
products for more than a decade. </p>
      </Fade>
      </Col>
      </Row>
    </Col>


    <Col sm={12} className="">

          <Fade up delay={200}>
           <Title40 >See What  <span style={{color:"#127DC2"}}>Our Client Say’s</span></Title40>
         </Fade>

     
            </Col>

    
              {
                testmonialCollection?.filter((item:any)=> item.content_type=== "video" )?.sort((a:any,b:any) => a.orderAt - b.orderAt)?.map((val:any,index:number)=>{
                  return   <Col lg={4} sm={6}> <TestimonialCards data={val} key={index} />  </Col>
                })
              }
           
    
        </Row>
      </Container>

      </BackgroundContainer>


    </>
  );
};


interface data {
  data : any
}
const TestimonialCards = ({data} : data) =>{
  return(<>
  <Card style={{borderRadius : 8 , boxShadow: '0px 3px 1px 0px rgba(0, 0, 0, 0.10)'}} className="p-3">
    
  <div
  className="d-flex flex-column justify-content-center align-items-center w-100">
<iframe width="100%" height="250px" style={{ borderRadius: "8px", overflow: "hidden" }} src={data.video_link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>   
</div>
{data.testmonial_name && <div className="para-box mt-2">
  <p className="text-black" style={{fontWeight : 500}}>{data.testmonial_name}</p>
</div> }


  </Card>
  </>)
}


