import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import {
  CounterContainer,
  ParagraphText,
  Title,
} from "../../Styles/StyleComponent";

import { GlobalData } from "../../contextapi/ContextProvider";


import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';



export const Counter = () => {


  let {
    achievementCollection
    } = useContext(GlobalData);


  return (
    <div id="counterss" className=""  >
      <Row className="mb-4">
        <Col md={12} className="text-center text-white">
          <Fade up delay={100}>
            <Title>
            {achievementCollection.achievement_title ||  "Trusted by 1000+ Happy Customers: Our Commitment to Excellence"}
            </Title>
          </Fade>
          <Fade up delay={150}>
            <ParagraphText >
            {achievementCollection.achievement_description ||     "At Dynamic Technosoft, we prioritize creating satisfied customers and nurturing long-lasting relationships.Our success is gauged by tangible results, with the most critical measure being how our clients perceive their experience with us." } 
</ParagraphText>
          </Fade>
        </Col>
      </Row>
      <Container className="py-4">
        <Row className="gy-4 justify-content-md-between justify-content-center align-items-center">
          <CounterContainer  lg={4} sm={6}>
            <span style={{background : '#DFF3FE' , padding : '14px' , borderRadius : '50px'}}>
            <img src={achievementCollection.achievement_1_image} alt="img" style={{height : '30px', width : '30px'}} />
            </span>
            <div className="ms-3">
              <h1
                style={{
                  color: "#0082C6",
                  fontWeight: "bold",
                  margin: "0px",
                }}
              >
                <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
      {({ isVisible }) => (
        <p className='fs-55-800'>
            {isVisible ? <CountUp  start={0} end={achievementCollection.achievement_1_count} /> : null} {achievementCollection.achievement_1_sufix}
          </p>
        )}
        </VisibilitySensor>


              </h1>
              <p className="m-0 p-0">{achievementCollection.achievement_1_title}</p>
            </div>
          </CounterContainer>
          <CounterContainer  lg={4} sm={6}>
          <span style={{background : '#DFF3FE' , padding : '14px' , borderRadius : '50px'}}>
            <img src={achievementCollection.achievement_2_image} alt="img" style={{height : '30px', width : '30px'}} />
            </span>
            <div className="ms-3">
              <h1
                style={{
                  color: "#0082C6",
                  fontWeight: "bold",
                  margin: "0px",
                }}
              >
                          <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
      {({ isVisible }) => (
        <p className='fs-55-800'>
            {isVisible ? <CountUp  start={0} end={achievementCollection.achievement_2_count} /> : null} {achievementCollection.achievement_2_sufix}
          </p>
        )}
        </VisibilitySensor>
              </h1>
              <p className="m-0 p-0">{achievementCollection.achievement_2_title}</p>
            </div>
          </CounterContainer>
          <CounterContainer  lg={4} sm={6}>
          <span style={{background : '#DFF3FE' , padding : '14px' , borderRadius : '50px'}}>
            <img src={achievementCollection.achievement_3_image} alt="img" style={{height : '30px', width : '30px'}} />
            </span>

            <div className="ms-3">
              <h1
                style={{
                  color: "#0082C6",
                  fontWeight: "bold",
                  margin: "0px",
                }}
              >
                          <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
      {({ isVisible }) => (
        <p className='fs-55-800'>
            {isVisible ? <CountUp  start={0} end={achievementCollection.achievement_3_count} /> : null} {achievementCollection.achievement_3_sufix}
          </p>
        )}
        </VisibilitySensor>
              </h1>
              <p className="m-0 p-0">{achievementCollection.achievement_3_title} </p>
            </div>
          </CounterContainer>
          <CounterContainer  lg={4} sm={6}>
          <span style={{background : '#DFF3FE' , padding : '14px' , borderRadius : '50px'}}>
            <img src={achievementCollection.achievement_4_image} alt="img" style={{height : '30px', width : '30px'}} />
            </span>

            <div className="ms-3">
              <h1
                style={{
                  color: "#0082C6",
                  fontWeight: "bold",
                  margin: "0px",
                }}
              >
                                <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
      {({ isVisible }) => (
        <p className='fs-55-800'>
            {isVisible ? <CountUp  start={0} end={achievementCollection.achievement_4_count} /> : null} {achievementCollection.achievement_4_sufix}
          </p>
        )}
        </VisibilitySensor>
              </h1>
              <p className="m-0 p-0">{achievementCollection.achievement_4_title}</p>
            </div>
          </CounterContainer>
        </Row>
      </Container>
    </div>
  );
};
