import { Card, Col, Container, Row } from "react-bootstrap";
import { strings } from "../../Api/Localization";
import {
  BR,
  ClearText,
  LocationDetailBox,
  ParagraphText,
  ProductBox,
  SubTitle,
  SubTitleText,
  Title,
  Title40,
  TitleText,
} from "../../Styles/StyleComponent";
import { CoverImage } from "../helper/CoverImage";
import teamImage from "../../Assets/coverImage/team.png";
import { ManWithVector, Objective, Vision } from "../../Assets/AboutSection";
import { MailIcon, PhoneIcon } from "../Navbar/Icon/Icon";
import { LocationIcon } from "../../Assets/SocialMediaIcon";
import { Fade } from "react-reveal";
import otherpagebg from "../../Assets/new/otherpagebg.png";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";

export const AboutCompany = () => {

  let {
    allpagesCollection,mobile
    } = useContext(GlobalData);


    const CardData = [
      {
        id : 0,
        title : "One-Stop Shop Convenience",
        description : "Dynamic Technosoft pioneers the one-stop-shop concept, empowering our clients to conveniently outsource solutions for all their diverse needs. Our diverse range of services includes ERP/Customized Software Development, Mobile App Development, Website Development, Bulk SMS Solutions, Domain Registration and Web Hosting, as well as Online Attendance Solutions. With a network spanning 7 provinces and over 50 dealer connections throughout Nepal, we've cultivated an extensive partner ecosystem that fosters collaboration and harnesses technology independence."
      },
       {
        id : 1,
        title : "Our Vision for the Future",
        description : "At Dynamic, our vision is to continually adapt and expand our service portfolio to meet the evolving needs of our clients. Our dedicated team of Engineers, Software Developers, IT and Business Professionals boasts years of experience in the field, enabling us to provide tailored solutions for your company's unique requirements. This commitment to excellence empowers enterprises at every level to maximize their profitability. We take pride in serving major corporate giants like Hulas Steel, Dugar Group, Nissan Motors, and many others, who rely on our products and services to effectively manage their operations."
      },
       {
        id : 2,
        title : "More Than IT Services",
        description : (<p>
          Dynamic Technosoft transcends being a mere IT service provider. With our Dynamic vision, we are on a mission to digitize Nepal and drive economic prosperity. Our groundbreaking products such as Academic ERP and Hospital ERP are revolutionizing vital healthcare and educational institutions, enabling them to offer superior services.<br/>
        As an IT service provider, we understand the pivotal role that technology plays in uplifting businesses. We eagerly anticipate the opportunity to become your trusted partner on the road to success.
        </p>)
      },
    ]

  return (
    <>

    <BackgroundContainer title={allpagesCollection.about_title || `Dynamic Technosoft
Your Premier IT Service Provider`} description={'You can contact us at any time for support. We value our customers more than anything & put our best to provide you with the best quality support.'} >
  
         <Container className="mt-5">

          <Row>

          <Col xs={12} style={{marginBottom : mobile ? 20: 60}}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Our Company</span></Title>
           <p>Introducing Dynamic Technosoft, your one-stop shop for innovative software and a comprehensive range of IT services.</p>
      </Fade>
      </Col>
      </Row>
    </Col>


  
      <Col lg={5}>
      <Fade up delay={200}> <Title40 > <span style={{color:"#233d63"}}>{allpagesCollection.about_title}</span></Title40>
      </Fade>
      </Col>
    
          <Col xs={12}>
          <Fade up delay={300}>
          <p dangerouslySetInnerHTML={{ __html: allpagesCollection.about_description || '' }}></p>
          
</Fade>
          </Col>
          </Row>

          <Row>
         {CardData.map((val,index) =>{
          return(<>
   
          <Col lg={4} md={6}  className="my-lg-3 my-3" key={index}>
          <Fade up delay={(index+1)  *100}>
             <div className="cardsServices-new">
                <h4 className="mt-2 text-center border-bottom w-100 p-3" style={{color : '#0082C6'}}>{val.title}</h4>
                <Card.Body className="p-4">{val.description}</Card.Body>
              </div>
              </Fade>
            </Col>
       

          </>)
         })   }
          </Row>
  

        {/* <ParagraphText color="#000">
          {strings.getString("about_company_description_first_paragraph")}
          <BR />
          {strings.getString("about_company_description_second_paragraph")}
          <BR />
          {strings.getString("about_company_description_third_paragraph")}
          <BR />
          {strings.getString("about_company_description_forth_paragraph")}
          <BR />
          {strings.getString("about_company_description_fifth_paragraph")}
        </ParagraphText> */}

        <Row className="py-4">
          <Col sm={6} md={7} className="bg-grey order-2 order-sm-1">
            <h5
              className=" fw-bold text-start m-0 mb-2"
              style={{ color: "#0082C6" }}
            >
             Our Mission: Leading with Vision, Performance, & Integrity
            </h5>
            <ParagraphText color="#000">
            Dynamic Technosoft is firmly committed to a vision that boldly addresses the challenges of the future. Our mission
is to cultivate a culture centered on exceptional performance, steadfastly paired with unwavering integrity. Our
guiding light, the CLEAR Values, drives us to continually adapt and elevate our standards.
              <BR />
              Our clients are the cornerstone of our existence. In unison with our clients, we tackle complex issues, strategically
minimizing business risk while seizing the full spectrum of opportunities. At Dynamic Technosoft, our core belief is
that we are only as strong as our client partnerships.
            </ParagraphText>
          </Col>
          <Col
            sm={6}
            md={5}
            className=" d-flex justify-content-sm-end justify-content-center  order-1 order-sm-2"
          >
            <ManWithVector />
          </Col>
        </Row>

        <Row className="py-4">
          <Col sm={6} md={5} className="d-flex justify-content-start">
            <Vision />
          </Col>
          <Col sm={6} md={7} className="bg-grey">
            <h5
              className="fw-bold text-start m-0 mb-2"
              style={{ color: "#0082C6" }}
            >
              {strings.getString("our_vision")}
            </h5>
            <ParagraphText color="#000">
              <ClearText color="#0082C6">Client-Centric Focus</ClearText>:
              <span> Our success hinges on the utmost satisfaction of our valued customers. We are unwaveringly dedicated to delivering exceptional service and unmatched value.</span>
              <br />
              <ClearText color="#0082C6">Industry Leadership</ClearText>:
              <span> Our innovative services spearhead a digital revolution, effectively addressing significant challenges within our communities and companies.</span>
              <br />
              <ClearText color="#0082C6">Efficacy and Solutions</ClearText>:
              <span> We are wholeheartedly committed to providing 100% effective solutions to our clients' most pressing challenges.</span>
              <br />
              <ClearText color="#0082C6">Continuous Aspiration</ClearText>:
              <span> We continually aspire to elevate the quality of our services and fortify our collective growth as a company.</span>
              <br />
              <ClearText color="#0082C6">Accountability for Results</ClearText>:
              <span> We take full responsibility for our commitments and remain steadfastly accountable for delivering the expected results.</span>
            </ParagraphText>
          </Col>
        </Row>



   
         </Container>
  
      </BackgroundContainer>
    </>
  );
};
